import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useForm } from "../../hooks/useForm";
import isEmail from "validator/lib/isEmail";
import { esborrarErrorAction, crearErrorAction, uiModalUserAction } from "../../actions/ui.js";
import { useTranslation } from "react-i18next";
import Modal from 'react-modal';
import { iniciarPeticioModificarAsincronaAxios } from "../../actions/auth";



  
const customStyles = {
  content: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60%', // Ajusta este valor según tus necesidades
    maxWidth: '800px', // Puedes agregar un valor máximo para evitar que se expanda demasiado
    height: 'auto', // Puedes ajustar la altura si lo necesitas
    maxHeight: '80vh', // Para asegurar que el modal no sea más alto que el viewport
    bgcolor: 'background.paper',
    boxShadow: 24,
    padding: '20px',
  }
};

export const ModificarScreen = () => {
   const dispatch = useDispatch(); 
   const state = useSelector((state) => state);
  const { auth,configUsuari } = useSelector((state) => state); 
   const {modalUser, msgError } = useSelector((state) => state.ui);
   const [t, i18n] = useTranslation("generic");
   const [passwordShown, setPasswordShown] = useState(false);
   const [msgError2, setMsgError2] = useState(false);
   const navegacio = useNavigate();


const closeModal = () => {
    // TODO: cerrar el modal
    dispatch( uiModalUserAction(false) );
    
}
const [formValuesMunicipio, handleInputChangeMun] = useForm({
    Nombre_Mun: configUsuari.contingut.nombre_Mun,
    CP_Mun: configUsuari.contingut.cp_Mun,
    Habitantes_Mun: configUsuari.contingut.habitantes_Mun,
    PagWeb_Mun: configUsuari.contingut.pagWeb_Mun,
    
});
const { Nombre_Mun, CP_Mun, Habitantes_Mun, PagWeb_Mun } = formValuesMunicipio;

const [formValuesContacto, handleInputChangeCon] = useForm({
    Nombre_Resp: configUsuari.contingut.nombre,
    Apellidos_Resp: configUsuari.contingut.apellido,
    Email_Resp: configUsuari.contingut.email,
    Telf_Resp:configUsuari.contingut.telf,
    Correo_Supervisor:configUsuari.contingut.email_supervisor,
    Nombre_Supervisor:configUsuari.contingut.nom_supervisor
});
const { Nombre_Resp, Apellidos_Resp, Email_Resp, Pwd_Resp,Telf_Resp, Correo_Supervisor,Nombre_Supervisor} = formValuesContacto;



useEffect(() => {
    //Mirem si la propietat estaLoginat canvia. Si es així, redireccionem a la part privada
    if (auth.tokenJwt !== undefined) {
      navegacio("/");
      setMsgError2(false);
    }
  }, [auth]);

  const handleRegister= (e) => {
    e.preventDefault();
    if (isFormValid()) {
      setMsgError2(true);
      //Todo Cridar a API de login. SI tot es OK, crearem l'acció de Login amb les dades de l'usuari rebudes del servei
      dispatch(iniciarPeticioModificarAsincronaAxios(auth.tokenJwt,Nombre_Resp, Apellidos_Resp,Email_Resp,Telf_Resp,Correo_Supervisor,Nombre_Supervisor,Nombre_Mun,Habitantes_Mun,PagWeb_Mun,CP_Mun))
    } else {
    }
  };
  
  

  const isFormValid = () => {
    let esCorrecte = true;
    if (Email_Resp.trim().length === 0 ) {
      setMsgError2(true);
      dispatch(crearErrorAction("Camps obligatoris"));
      esCorrecte = false;
    } else if (!isEmail(Email_Resp)) {
      setMsgError2(true);
      dispatch(crearErrorAction("Email incorrecto"));
      esCorrecte = false;
    } else {
      setMsgError2(false);
      dispatch(esborrarErrorAction());
    }

    return esCorrecte;
  };

  return (
      <Modal id="modalUser"
          isOpen={ modalUser }
          style={ customStyles }
          closeTimeoutMS={ 200 }
          className="modal fade bd-example-modal-xl show"
          ariaHideApp={false}
          backdrop="static"
          keyboard={false}
          overlayClassName="modal-fondo"
        >
          <button type="button" onClick={closeModal} className="btn-close align-right" data-bs-dismiss="modal" aria-label="Close">{t("login.txtClose")}</button>
                <div className='modal-body'>
                <div className="position-relative">
                    <div className="card card-shadow card-login">
          <div className="container">
            <div className="">
              <div className="">
                {msgError != null && msgError2 ? ( //això es una condició, si es diferent de null
                  <div className="alert alert-danger">{msgError}</div>
                ) : null}

                <div className="text-center">
                  <div className="mb-5">
                  <h4 className="mt-4 text-[22px] leading-[34px] font-semibold ">{t("login.titleModificar")}</h4>
                  </div>
                </div>

                <div> {/* DIV DEL FORMULARI MUNICIPI i CONTACTE +boto registar*/}
                  <h1 className="mt-4 text-[11px] leading-[17px] font-semibold">{t("login.txtMunicipio")}</h1>
                  <br></br>
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-6"> {/* DIV DEL FORMULARI MUNICIPI */}
                    
                    
                    <div >
                      <label>{t("login.txtMunicipoNombre")}</label>
                      <input
                        type="text"
                        value={Nombre_Mun}
                        className="form-input"
                        name="Nombre_Mun"
                        id="Nombre_Mun"
                        tabIndex="1"
                        placeholder={t("login.txtMunicipi")}
                        onChange={handleInputChangeMun}
                        required
                      />
                    </div>
                    <div>
                    <label>{t("login.txtCp")}</label>
                      <input
                        type="text"
                        value={CP_Mun}
                        className="form-input"
                        name="CP_Mun"
                        id="CP_Mun"
                        tabIndex="1"
                        placeholder={t("login.txtCp")}
                        onChange={handleInputChangeMun}
                        required
                      />
                    </div>
                    <div>
                    <label>{t("login.txtHabitants")}</label>
                      <input
                        type="text"
                        value={Habitantes_Mun}
                        className="form-input"
                        name="Habitantes_Mun"
                        id="Habitantes_Mun"
                        tabIndex="1"
                        placeholder={t("login.txtHabitants")}
                        onChange={handleInputChangeMun}
                        required
                      />
                    </div>
                    <div>
                    <label>{t("login.txtWeb")}</label>
                      <input
                        type="text"
                        value={PagWeb_Mun}
                        className="form-input"
                        name="PagWeb_Mun"
                        id="PagWeb_Mun"
                        tabIndex="1"
                        placeholder={t("login.txtWeb")}
                        onChange={handleInputChangeMun}
                        required
                      />
                    </div>
                  </div>
<br></br>
                  <h1 className="mt-4 text-[11px] leading-[17px] font-semibold">{t("login.txtContacte")} </h1>
                  <br></br>
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-6"> {/* DIV DEL FORMULARI CONTACTE */}
                    <div>
                    <label>{t("login.txtNom")}</label>
                      <input
                        type="text"
                        value={Nombre_Resp}
                        className="form-input"
                        name="Nombre_Resp"
                        id="Nombre_Resp"
                        tabIndex="1"
                        placeholder={t("login.txtNom")}
                        onChange={handleInputChangeCon}
                        required
                      />
                    </div>
                    <div>
                    <label>{t("login.txtCognom")}</label>
                      <input
                        type="text"
                        value={Apellidos_Resp}
                        className="form-input"
                        name="Apellidos_Resp"
                        id="Apellidos_Resp"
                        tabIndex="1"
                        placeholder={t("login.txtCognom")}
                        onChange={handleInputChangeCon}
                        required
                      />
                    </div>
                    <div>
                    <label>{t("login.txtEmail")}</label>
                      <input
                        type="text"
                        value={Email_Resp}
                        className="form-input"
                        name="Email_Resp"
                        id="Email_Resp"
                        tabIndex="1"
                        placeholder={t("login.txtEmail")}
                        onChange={handleInputChangeCon}
                        required
                      />
                    </div>

                    <div>
                    <label>{t("login.txtTelf_Resp")}</label>
                      <input
                        type="text"
                        value={Telf_Resp}
                        className="form-input"
                        name="Telf_Resp"
                        id="Telf_Resp"
                        tabIndex="1"
                        placeholder={t("login.txtTelf_Resp")}
                        onChange={handleInputChangeCon}
                        required
                      />
                    </div>
                    <div>
                    <label>{t("login.txtCorreo_Supervisor")}</label>
                      <input
                        type="text"
                        value={Correo_Supervisor}
                        className="form-input"
                        name="Correo_Supervisor"
                        id="Correo_Supervisor"
                        tabIndex="1"
                        placeholder={t("login.txtCorreo_Supervisor")}
                        onChange={handleInputChangeCon}
                        required
                      />
                    </div>
                    <div>
                    <label>{t("login.txtNombre_Supervisor")}</label>
                      <input
                        type="text"
                        value={Nombre_Supervisor}
                        className="form-input"
                        name="Nombre_Supervisor"
                        id="Nombre_Supervisor"
                        tabIndex="1"
                        placeholder={t("login.txtNombre_Supervisor")}
                        onChange={handleInputChangeCon}
                        required
                      />
                    </div>


            
                  </div>
                  <div className="d-grid gap-6 mt-4">
                    <button
                      onClick={handleRegister}
                      className="btn-green"
                      
                    >
                      {t("login.btnModificar")}
                    </button>
                  </div>
                </div>

                
              </div>
            </div>
            
          </div>
                    </div>
                </div>
                </div>

        
                </Modal>

      
    
  );
};
