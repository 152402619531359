import React from 'react'
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addFormularioCat1, addFormularioCat6, addProgramaCat6 } from '../../actions/configFormularioAction';
import { crearErrorAction } from '../../actions/ui';
import { calculoPorcentaje } from '../../helpers/FormulasCat';
import { useForm } from "../../hooks/useForm";
import { useTranslation } from "react-i18next";


export const FormularioCat6 = () => {
  const dispatch = useDispatch(); 
  const [t, i18n] = useTranslation("generic");
  const state = useSelector((state) => state);
  const { msgError } = useSelector((state) => state.ui);
  const { auth,configFormulario} = useSelector(state => state);
  const [formValues, handleInputChange,reset] = useForm({
    cat6_1: "",
    cat6_2: "",
    cat6_3: "",
    cat6_4: "",
    cat6_5: "",
    cat6_6: "",
    cat6_7: "",   
});

const { cat6_1, cat6_2, cat6_3, cat6_4, cat6_5, cat6_6,cat6_7} = formValues;

  const handleCalcularCat = (e) => {
    
    if(validarFormulas()){
      
      dispatch(addFormularioCat6(auth.tokenJwt,cat6_1, cat6_2, cat6_3, cat6_4, cat6_5, cat6_6,cat6_7,configFormulario.infoFormulario.IdEvaluacion));
      
      reset()

    }else{
      console.log("out");
      //setMsgError("")
    }
  };

  const validarFormulas = () => {
    let esCorrecte = true;
    let msgError ="";
    if(isNaN(cat6_1) || !cat6_1 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 6.1 + t("formulario.msgError2");}
    if(isNaN(cat6_2) || !cat6_2 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 6.2 + t("formulario.msgError2");}
    if(isNaN(cat6_3) || !cat6_3 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 6.3 + t("formulario.msgError2");}
    if(isNaN(cat6_4) || !cat6_4 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 6.4 + t("formulario.msgError2");}
    if(isNaN(cat6_5) || !cat6_5 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 6.5 + t("formulario.msgError2");}
    if(isNaN(cat6_6) || !cat6_6 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 6.6 + t("formulario.msgError2");}
    if(isNaN(cat6_7) || !cat6_7 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 6.7 + t("formulario.msgError2");}
  

    
    if(!esCorrecte){
      dispatch(crearErrorAction(msgError));
    }
    return esCorrecte;
  }
  const handleCalcularPrograma = (e) => {
    if(validarProgramas()){ 
      dispatch(addProgramaCat6(auth.tokenJwt,cat6_1,configFormulario.infoFormulario.IdEvaluacion));
    }else{
      console.log("out");
    }
  };
  const validarProgramas = () => {
    let esCorrecte = true;
    let msgError ="";
    if(isNaN(cat6_1) || !cat6_1 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 6.1 + t("formulario.msgError2");}
    if(!esCorrecte){
      dispatch(crearErrorAction(msgError));
    }
    return esCorrecte;
  }

  return (
    <>
    <div className='ac-panel'>
      <div className="ac-text !p-9 !pt-3 !text-[15px] !font-normal !leading-7 !text-dark-light">
        {msgError != null  ? ( //això es una condició, si es diferent de null
                      <div className="alert alert-danger">{msgError}</div>
                    ) : null}
                    {configFormulario.infoFormularioCategoria.ResultatCat6==null?
    <>
        <div className='sm:col-span-2'>
          <label htmlFor="cat6_1">{t("Categoria6.preg1")} </label>
          <select className='form-inputCat' id={cat6_1} name="cat6_1" defaultValue="" onChange={handleInputChange}>
            <option value=''>{t("CategoriaDesplegable.seleccionar")}</option>
            <option value="1">{t("CategoriaDesplegable.6_si")} </option>
            <option value="0">{t("CategoriaDesplegable.6_no")} </option>
          </select>
        </div>
        <div className='sm:col-span-2'>
          <label htmlFor="cat6_2">{t("Categoria6.preg2")} </label>
          <input className='form-inputCat' type="text" name="cat6_2" id="cat6_2"  onChange={handleInputChange} required/>
        </div>
        <div className='sm:col-span-2'>
          <label htmlFor="cat6_3">{t("Categoria6.preg3")}</label>
          <select className='form-inputCat' id={cat6_3} name="cat6_3" defaultValue="" onChange={handleInputChange}>
            <option value=''>{t("CategoriaDesplegable.seleccionar")}</option>
            <option value="1">{t("CategoriaDesplegable.6_si")} </option>
            <option value="0">{t("CategoriaDesplegable.6_no")} </option>
          </select>
        </div>
        <div className='sm:col-span-2'>
          <label htmlFor="cat6_4">{t("Categoria6.preg4")} </label>
          <input className='form-inputCat' type="text" name="cat6_4" id="cat6_4"  onChange={handleInputChange} required/>
        </div>
        <div className='sm:col-span-2'>
          <label htmlFor="cat6_5">{t("Categoria6.preg5")}</label>
          <input className='form-inputCat' type="text" name="cat6_5" id="cat6_5"  onChange={handleInputChange} required/>
        </div>
        <div className='sm:col-span-2'>
          <label htmlFor="cat6_6">{t("Categoria6.preg6")}</label>
          <input className='form-inputCat' type="text" name="cat6_6" id="cat6_6"  onChange={handleInputChange} required/>
        </div>
        <div className='sm:col-span-2'>
          <label htmlFor="cat6_7">{t("Categoria6.preg7")}</label>
          <input className='form-inputCat' type="text" name="cat6_7" id="cat6_7"  onChange={handleInputChange} required/>
        </div>
        <div><button onClick={handleCalcularCat} id='cat1' className="btn-turquesa">{t("formulario.enviar")}</button></div>
        </>
      :<>
      <div className='sm:col-span-2'>
          <label htmlFor="cat6_1">{t("CategoriaPrograma.6_1")} </label>
          <select className='form-inputCat' id={cat6_1} name="cat6_1" value={cat6_1} onChange={handleInputChange}>
            <option value=''>{t("CategoriaDesplegable.seleccionar")}</option>
            <option value="2">{t("CategoriaDesplegable.si")} </option>
            <option value="1">{t("CategoriaDesplegable.mitat")} </option>
            <option value="0">{t("CategoriaDesplegable.no")}</option>
          </select>        </div>
    <div><button onClick={handleCalcularPrograma} id='cat1' className="btn-turquesa">{t("formulario.enviar")}</button></div>
    </> 
    }
      </div>
    </div>
    </>
  )
}
