import React from "react";
import { useEffect } from "react";
import { useState } from "react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {infoCategoria, recuperarConstantesIndicadoresFormulario } from "../../actions/configFormularioAction";
import { uiFinalitzarFormularioAction, esborrarErrorAction } from "../../actions/ui";
import { ModificarScreen } from "../register/ModificarScreen";
import { FormularioCat1 } from "./FormularioCat1";
import { FormularioCat2 } from "./FormularioCat2";
import { FormularioCat3 } from "./FormularioCat3";
import { FormularioCat4 } from "./FormularioCat4";
import { FormularioCat5 } from "./FormularioCat5";
import { FormularioCat6 } from "./FormularioCat6";
import { FormularioCat7 } from "./FormularioCat7";
import { FormularioCat8 } from "./FormularioCat8";
import { FormularioCat9 } from "./FormularioCat9";

export const FormulariPrincipal = () => {
  
  const [t, i18n] = useTranslation("generic");
  const dispatch = useDispatch();
  const [cat, setCat] = useState("1");
  const navegacio = useNavigate();
  const [primerCop, setPrimerCop] = useState(true);
  const { auth,configFormulario,ui,configUsuari} = useSelector(state => state);
  const {infoFormulario,infoFormularioCategoria} = configFormulario;
  

  const handleObrirCat = (e) => {
    e.preventDefault();
    if(e.target.id== cat){
      setCat(null);
    }else{
      setCat(e.target.id)
    }
    dispatch(esborrarErrorAction())
    dispatch(infoCategoria(auth.tokenJwt,infoFormulario.IdEvaluacion)); 
  };
  useEffect(() => {
    if(primerCop){
      if(infoFormulario != null){
        dispatch(infoCategoria(auth.tokenJwt,infoFormulario.IdEvaluacion)); 
      }
      if(infoFormularioCategoria !=null){
        setCat(infoFormularioCategoria.CatActual);
        setPrimerCop(false);
      }  
    }
  }, [configFormulario,cat]);

  const [disabled, setDisabled] = useState({
    cat1: false,
    cat2: false,
    cat3: false,
    cat4: false,
    cat5: false,
    cat6: false,
    cat7: false,
    cat8: false,
    cat9: false,
    cat10:false
    
});
  if(infoFormularioCategoria !=null){
    if(infoFormularioCategoria.ResultatCat1Final && disabled.cat1 == false){
     setDisabled({... disabled, cat1: true});
    }
    if(infoFormularioCategoria.ResultatCat2Final && disabled.cat2 == false){
      setDisabled({... disabled, cat2: true});
     }
     if(infoFormularioCategoria.ResultatCat3Final && disabled.cat3 == false){
      setDisabled({... disabled, cat3: true});
     }
     if(infoFormularioCategoria.ResultatCat4Final && disabled.cat4 == false){
      setDisabled({... disabled, cat4: true});
     }
     if(infoFormularioCategoria.ResultatCat5Final && disabled.cat5 == false){
      setDisabled({... disabled, cat5: true});
     }
     if(infoFormularioCategoria.ResultatCat6Final && disabled.cat6 == false){
      setDisabled({... disabled, cat6: true});
     }
     if(infoFormularioCategoria.ResultatCat7Final && disabled.cat7 == false){
      setDisabled({... disabled, cat7: true});
     }
     if(infoFormularioCategoria.ResultatCat8Final && disabled.cat8 == false){
      setDisabled({... disabled, cat8: true});
     }
     if(infoFormularioCategoria.ResultatCat9Final && disabled.cat9 == false){
      setDisabled({... disabled, cat9: true});
     }
    

  }
  useEffect(() => {
    if(infoFormularioCategoria != null){
      if(infoFormularioCategoria.CatActual == null){
      dispatch(uiFinalitzarFormularioAction());

      }
     }
  }, [infoFormularioCategoria]);
  
  useEffect(() => {
    if(!ui.formulari){
      navegacio("/");
    }
    if(ui.msgError !== null){
      setTimeout(()=>dispatch(esborrarErrorAction()),10000);
    }  
  }, [ui]);
  
  return (
    <>
    {/* {configUsuari.contingut!=null?
          <ModificarScreen/>
        :null
        } */}
    <p className="uppercase font-medium text-sm tracking-[0.84px] after:bg-green-semilight after:absolute after:w-full after:h-2 relative after:left-0 inline-block after:bottom-0 after:-z-10">{configUsuari.contingut.nombre_Mun}</p>
      <h2 className="font-semibold mt-4 mb-4 text-4xl leading-snug lg:text-5xl lg:leading-[62px]">Formulario de evaluación</h2>
      {infoFormularioCategoria !=null?
      <div className="accordion-container faq space-y-4">
        
        <div className="ac mt-0 bg-white rounded-lg border-none">
        <h2 className="ac-header">
        <button onClick={handleObrirCat} id='1' className="ac-trigger !px-8 !py-[18px] relative !flex w-full items-center justify-between gap-2 !text-lg !font-nunito !font-semibold !text-dark after:!hidden" disabled={disabled.cat1} >
        <span className="text-medium text-dark-semilight uppercase">{t("formulario.titleCat1")}</span>
        <div className="leading-[22px] faq-arrow">
        {infoFormularioCategoria.ResultatCat1Final ==null?
              cat !=1?
                <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.0466659 0.744778L3.86978 5.53751C3.93301 5.61663 4.06672 5.61663 4.12995 5.53751L7.95348 0.744445C7.99899 0.68712 8.01247 0.601674 7.98793 0.527294C7.98285 0.511735 7.97719 0.499838 7.97245 0.490935C7.94325 0.436855 7.88717 0.403076 7.8256 0.403076H0.174128C0.112893 0.403076 0.0564835 0.436772 0.02753 0.490935C0.0226212 0.500004 0.0169636 0.511985 0.0122212 0.526378C-0.0124892 0.601674 0.000906017 0.687287 0.0466659 0.744778Z" fill="#e20078" />
                </svg>
              : <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.0466659 0.744778L3.86978 5.53751C3.93301 5.61663 4.06672 5.61663 4.12995 5.53751L7.95348 0.744445C7.99899 0.68712 8.01247 0.601674 7.98793 0.527294C7.98285 0.511735 7.97719 0.499838 7.97245 0.490935C7.94325 0.436855 7.88717 0.403076 7.8256 0.403076H0.174128C0.112893 0.403076 0.0564835 0.436772 0.02753 0.490935C0.0226212 0.500004 0.0169636 0.511985 0.0122212 0.526378C-0.0124892 0.601674 0.000906017 0.687287 0.0466659 0.744778Z" fill="#3EC37D"></path>
                </svg>
              :infoFormularioCategoria.ResultatCat1Final !=null?<div className="leading-[22px] faq-arrow"><p className="text-[22px] font-semibold text-green">{infoFormularioCategoria.ResultatCat1Final}</p></div>
              :null}
        </div>
        </button>
        {cat ==1 && infoFormularioCategoria.ResultatCat1Final == null ?<FormularioCat1 />
        : null}
        </h2>
        </div>

        <div className="ac mt-0 bg-white rounded-lg border-none">
        <h2 className="ac-header">
            <button onClick={handleObrirCat} id='2' className="ac-trigger !px-8 !py-[18px] relative !flex w-full items-center justify-between gap-2 !text-lg !font-nunito !font-semibold !text-dark after:!hidden" disabled={disabled.cat2} >
              <span className="text-medium text-dark-semilight uppercase">{t("formulario.titleCat2")}</span>
              <div className="leading-[22px] faq-arrow">
              {infoFormularioCategoria.ResultatCat2Final ==null?
              cat !=2?
                <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.0466659 0.744778L3.86978 5.53751C3.93301 5.61663 4.06672 5.61663 4.12995 5.53751L7.95348 0.744445C7.99899 0.68712 8.01247 0.601674 7.98793 0.527294C7.98285 0.511735 7.97719 0.499838 7.97245 0.490935C7.94325 0.436855 7.88717 0.403076 7.8256 0.403076H0.174128C0.112893 0.403076 0.0564835 0.436772 0.02753 0.490935C0.0226212 0.500004 0.0169636 0.511985 0.0122212 0.526378C-0.0124892 0.601674 0.000906017 0.687287 0.0466659 0.744778Z" fill="#e20078" />
                </svg>
              : <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.0466659 0.744778L3.86978 5.53751C3.93301 5.61663 4.06672 5.61663 4.12995 5.53751L7.95348 0.744445C7.99899 0.68712 8.01247 0.601674 7.98793 0.527294C7.98285 0.511735 7.97719 0.499838 7.97245 0.490935C7.94325 0.436855 7.88717 0.403076 7.8256 0.403076H0.174128C0.112893 0.403076 0.0564835 0.436772 0.02753 0.490935C0.0226212 0.500004 0.0169636 0.511985 0.0122212 0.526378C-0.0124892 0.601674 0.000906017 0.687287 0.0466659 0.744778Z" fill="#3EC37D"></path>
                </svg>
              :infoFormularioCategoria.ResultatCat2Final !=null?<div className="leading-[22px] faq-arrow"><p className="text-[22px] font-semibold text-green">{infoFormularioCategoria.ResultatCat2Final}</p></div>
              :null}
              </div>
              </button>
        {cat ==2 && infoFormularioCategoria.ResultatCat2Final ==null ?<FormularioCat2 />
        : null}
        
        </h2>
        </div>

        <div className="ac mt-0 bg-white rounded-lg border-none">
        <h2 className="ac-header">
            <button onClick={handleObrirCat} id='3' className="ac-trigger !px-8 !py-[18px] relative !flex w-full items-center justify-between gap-2 !text-lg !font-nunito !font-semibold !text-dark after:!hidden" disabled={disabled.cat3} >
              <span className="text-medium text-dark-semilight uppercase">{t("formulario.titleCat3")}</span>
              <div className="leading-[22px] faq-arrow">
              {infoFormularioCategoria.ResultatCat3Final ==null?
              cat !=3?
                <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.0466659 0.744778L3.86978 5.53751C3.93301 5.61663 4.06672 5.61663 4.12995 5.53751L7.95348 0.744445C7.99899 0.68712 8.01247 0.601674 7.98793 0.527294C7.98285 0.511735 7.97719 0.499838 7.97245 0.490935C7.94325 0.436855 7.88717 0.403076 7.8256 0.403076H0.174128C0.112893 0.403076 0.0564835 0.436772 0.02753 0.490935C0.0226212 0.500004 0.0169636 0.511985 0.0122212 0.526378C-0.0124892 0.601674 0.000906017 0.687287 0.0466659 0.744778Z" fill="#e20078" />
                </svg>
              : <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.0466659 0.744778L3.86978 5.53751C3.93301 5.61663 4.06672 5.61663 4.12995 5.53751L7.95348 0.744445C7.99899 0.68712 8.01247 0.601674 7.98793 0.527294C7.98285 0.511735 7.97719 0.499838 7.97245 0.490935C7.94325 0.436855 7.88717 0.403076 7.8256 0.403076H0.174128C0.112893 0.403076 0.0564835 0.436772 0.02753 0.490935C0.0226212 0.500004 0.0169636 0.511985 0.0122212 0.526378C-0.0124892 0.601674 0.000906017 0.687287 0.0466659 0.744778Z" fill="#3EC37D"></path>
                </svg>
              :infoFormularioCategoria.ResultatCat3Final !=null?<div className="leading-[22px] faq-arrow"><p className="text-[22px] font-semibold text-green">{infoFormularioCategoria.ResultatCat3Final}</p></div>
              :null}
              </div>
              </button>
        {cat ==3 && infoFormularioCategoria.ResultatCat3Final ==null ?<FormularioCat3 />
        : null}
        </h2>
        </div>

        <div className="ac mt-0 bg-white rounded-lg border-none">
        <h2 className="ac-header">
            <button onClick={handleObrirCat} id='4' className="ac-trigger !px-8 !py-[18px] relative !flex w-full items-center justify-between gap-2 !text-lg !font-nunito !font-semibold !text-dark after:!hidden" disabled={disabled.cat4} >
              <span className="text-medium text-dark-semilight uppercase">{t("formulario.titleCat4")}</span>
              <div className="leading-[22px] faq-arrow">
              {infoFormularioCategoria.ResultatCat4Final ==null?
              cat !=4?
                <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.0466659 0.744778L3.86978 5.53751C3.93301 5.61663 4.06672 5.61663 4.12995 5.53751L7.95348 0.744445C7.99899 0.68712 8.01247 0.601674 7.98793 0.527294C7.98285 0.511735 7.97719 0.499838 7.97245 0.490935C7.94325 0.436855 7.88717 0.403076 7.8256 0.403076H0.174128C0.112893 0.403076 0.0564835 0.436772 0.02753 0.490935C0.0226212 0.500004 0.0169636 0.511985 0.0122212 0.526378C-0.0124892 0.601674 0.000906017 0.687287 0.0466659 0.744778Z" fill="#e20078" />
                </svg>
              : <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.0466659 0.744778L3.86978 5.53751C3.93301 5.61663 4.06672 5.61663 4.12995 5.53751L7.95348 0.744445C7.99899 0.68712 8.01247 0.601674 7.98793 0.527294C7.98285 0.511735 7.97719 0.499838 7.97245 0.490935C7.94325 0.436855 7.88717 0.403076 7.8256 0.403076H0.174128C0.112893 0.403076 0.0564835 0.436772 0.02753 0.490935C0.0226212 0.500004 0.0169636 0.511985 0.0122212 0.526378C-0.0124892 0.601674 0.000906017 0.687287 0.0466659 0.744778Z" fill="#3EC37D"></path>
                </svg>
              :infoFormularioCategoria.ResultatCat4Final !=null?<div className="leading-[22px] faq-arrow"><p className="text-[22px] font-semibold text-green">{infoFormularioCategoria.ResultatCat4Final}</p></div>
              :null}
              </div>
              </button>
        {cat ==4 && infoFormularioCategoria.ResultatCat4Final ==null ?<FormularioCat4 />
        : null}
        </h2>
        </div>

        <div className="ac mt-0 bg-white rounded-lg border-none">
        <h2 className="ac-header">
            <button onClick={handleObrirCat} id='5' className="ac-trigger !px-8 !py-[18px] relative !flex w-full items-center justify-between gap-2 !text-lg !font-nunito !font-semibold !text-dark after:!hidden" disabled={disabled.cat5} >
              <span className="text-medium text-dark-semilight uppercase">{t("formulario.titleCat5")}</span>
              <div className="leading-[22px] faq-arrow">
              {infoFormularioCategoria.ResultatCat5Final ==null?
              cat !=5?
                <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.0466659 0.744778L3.86978 5.53751C3.93301 5.61663 4.06672 5.61663 4.12995 5.53751L7.95348 0.744445C7.99899 0.68712 8.01247 0.601674 7.98793 0.527294C7.98285 0.511735 7.97719 0.499838 7.97245 0.490935C7.94325 0.436855 7.88717 0.403076 7.8256 0.403076H0.174128C0.112893 0.403076 0.0564835 0.436772 0.02753 0.490935C0.0226212 0.500004 0.0169636 0.511985 0.0122212 0.526378C-0.0124892 0.601674 0.000906017 0.687287 0.0466659 0.744778Z" fill="#e20078" />
                </svg>
              : <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.0466659 0.744778L3.86978 5.53751C3.93301 5.61663 4.06672 5.61663 4.12995 5.53751L7.95348 0.744445C7.99899 0.68712 8.01247 0.601674 7.98793 0.527294C7.98285 0.511735 7.97719 0.499838 7.97245 0.490935C7.94325 0.436855 7.88717 0.403076 7.8256 0.403076H0.174128C0.112893 0.403076 0.0564835 0.436772 0.02753 0.490935C0.0226212 0.500004 0.0169636 0.511985 0.0122212 0.526378C-0.0124892 0.601674 0.000906017 0.687287 0.0466659 0.744778Z" fill="#3EC37D"></path>
                </svg>
              :infoFormularioCategoria.ResultatCat5Final !=null?<div className="leading-[22px] faq-arrow"><p className="text-[22px] font-semibold text-green">{infoFormularioCategoria.ResultatCat5Final}</p></div>
              :null}
              </div>
              </button>
        {cat ==5 && infoFormularioCategoria.ResultatCat5Final ==null ?<FormularioCat5 />
        : null}
        </h2>
        </div>

        <div className="ac mt-0 bg-white rounded-lg border-none">
        <h2 className="ac-header">
            <button onClick={handleObrirCat} id='6' className="ac-trigger !px-8 !py-[18px] relative !flex w-full items-center justify-between gap-2 !text-lg !font-nunito !font-semibold !text-dark after:!hidden" disabled={disabled.cat6} >
              <span className="text-medium text-dark-semilight uppercase">{t("formulario.titleCat6")}</span>
              <div className="leading-[22px] faq-arrow">
              {infoFormularioCategoria.ResultatCat6Final ==null?
              cat !=6?
                <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.0466659 0.744778L3.86978 5.53751C3.93301 5.61663 4.06672 5.61663 4.12995 5.53751L7.95348 0.744445C7.99899 0.68712 8.01247 0.601674 7.98793 0.527294C7.98285 0.511735 7.97719 0.499838 7.97245 0.490935C7.94325 0.436855 7.88717 0.403076 7.8256 0.403076H0.174128C0.112893 0.403076 0.0564835 0.436772 0.02753 0.490935C0.0226212 0.500004 0.0169636 0.511985 0.0122212 0.526378C-0.0124892 0.601674 0.000906017 0.687287 0.0466659 0.744778Z" fill="#e20078" />
                </svg>
              : <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.0466659 0.744778L3.86978 5.53751C3.93301 5.61663 4.06672 5.61663 4.12995 5.53751L7.95348 0.744445C7.99899 0.68712 8.01247 0.601674 7.98793 0.527294C7.98285 0.511735 7.97719 0.499838 7.97245 0.490935C7.94325 0.436855 7.88717 0.403076 7.8256 0.403076H0.174128C0.112893 0.403076 0.0564835 0.436772 0.02753 0.490935C0.0226212 0.500004 0.0169636 0.511985 0.0122212 0.526378C-0.0124892 0.601674 0.000906017 0.687287 0.0466659 0.744778Z" fill="#3EC37D"></path>
                </svg>
              :infoFormularioCategoria.ResultatCat6Final !=null?<div className="leading-[22px] faq-arrow"><p className="text-[22px] font-semibold text-green">{infoFormularioCategoria.ResultatCat6Final}</p></div>
              :null}
              </div>
              </button>
        {cat ==6 && infoFormularioCategoria.ResultatCat6Final ==null ?<FormularioCat6 />
        : null}
        </h2>
        </div>

        <div className="ac mt-0 bg-white rounded-lg border-none">
        <h2 className="ac-header">
            <button onClick={handleObrirCat} id='7' className="ac-trigger !px-8 !py-[18px] relative !flex w-full items-center justify-between gap-2 !text-lg !font-nunito !font-semibold !text-dark after:!hidden" disabled={disabled.cat7} >
              <span className="text-medium text-dark-semilight uppercase">{t("formulario.titleCat7")}</span>
              <div className="leading-[22px] faq-arrow">
              {infoFormularioCategoria.ResultatCat7Final ==null?
              cat !=7?
                <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.0466659 0.744778L3.86978 5.53751C3.93301 5.61663 4.06672 5.61663 4.12995 5.53751L7.95348 0.744445C7.99899 0.68712 8.01247 0.601674 7.98793 0.527294C7.98285 0.511735 7.97719 0.499838 7.97245 0.490935C7.94325 0.436855 7.88717 0.403076 7.8256 0.403076H0.174128C0.112893 0.403076 0.0564835 0.436772 0.02753 0.490935C0.0226212 0.500004 0.0169636 0.511985 0.0122212 0.526378C-0.0124892 0.601674 0.000906017 0.687287 0.0466659 0.744778Z" fill="#e20078" />
                </svg>
              : <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.0466659 0.744778L3.86978 5.53751C3.93301 5.61663 4.06672 5.61663 4.12995 5.53751L7.95348 0.744445C7.99899 0.68712 8.01247 0.601674 7.98793 0.527294C7.98285 0.511735 7.97719 0.499838 7.97245 0.490935C7.94325 0.436855 7.88717 0.403076 7.8256 0.403076H0.174128C0.112893 0.403076 0.0564835 0.436772 0.02753 0.490935C0.0226212 0.500004 0.0169636 0.511985 0.0122212 0.526378C-0.0124892 0.601674 0.000906017 0.687287 0.0466659 0.744778Z" fill="#3EC37D"></path>
                </svg>
              :infoFormularioCategoria.ResultatCat7Final !=null?<div className="leading-[22px] faq-arrow"><p className="text-[22px] font-semibold text-green">{infoFormularioCategoria.ResultatCat7Final}</p></div>
              :null}
              </div>
              </button>
        {cat ==7 && infoFormularioCategoria.ResultatCat7Final ==null ?<FormularioCat7 />
        : null}
        </h2>
        </div>

        <div className="ac mt-0 bg-white rounded-lg border-none">
        <h2 className="ac-header">
            <button onClick={handleObrirCat} id='8' className="ac-trigger !px-8 !py-[18px] relative !flex w-full items-center justify-between gap-2 !text-lg !font-nunito !font-semibold !text-dark after:!hidden" disabled={disabled.cat8} >
              <span className="text-medium text-dark-semilight uppercase">{t("formulario.titleCat8")}</span>
              <div className="leading-[22px] faq-arrow">
              {infoFormularioCategoria.ResultatCat8Final ==null?
              cat !=8?
                <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.0466659 0.744778L3.86978 5.53751C3.93301 5.61663 4.06672 5.61663 4.12995 5.53751L7.95348 0.744445C7.99899 0.68712 8.01247 0.601674 7.98793 0.527294C7.98285 0.511735 7.97719 0.499838 7.97245 0.490935C7.94325 0.436855 7.88717 0.403076 7.8256 0.403076H0.174128C0.112893 0.403076 0.0564835 0.436772 0.02753 0.490935C0.0226212 0.500004 0.0169636 0.511985 0.0122212 0.526378C-0.0124892 0.601674 0.000906017 0.687287 0.0466659 0.744778Z" fill="#e20078" />
                </svg>
              : <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.0466659 0.744778L3.86978 5.53751C3.93301 5.61663 4.06672 5.61663 4.12995 5.53751L7.95348 0.744445C7.99899 0.68712 8.01247 0.601674 7.98793 0.527294C7.98285 0.511735 7.97719 0.499838 7.97245 0.490935C7.94325 0.436855 7.88717 0.403076 7.8256 0.403076H0.174128C0.112893 0.403076 0.0564835 0.436772 0.02753 0.490935C0.0226212 0.500004 0.0169636 0.511985 0.0122212 0.526378C-0.0124892 0.601674 0.000906017 0.687287 0.0466659 0.744778Z" fill="#3EC37D"></path>
                </svg>
              :infoFormularioCategoria.ResultatCat8Final !=null?<div className="leading-[22px] faq-arrow"><p className="text-[22px] font-semibold text-green">{infoFormularioCategoria.ResultatCat8Final}</p></div>
              :null}
              </div>
              </button>
        {cat ==8 && infoFormularioCategoria.ResultatCat8Final ==null ?<FormularioCat8 />
        : null}
        </h2>
        </div>

        <div className="ac mt-0 bg-white rounded-lg border-none">
        <h2 className="ac-header">
            <button onClick={handleObrirCat} id='9' className="ac-trigger !px-8 !py-[18px] relative !flex w-full items-center justify-between gap-2 !text-lg !font-nunito !font-semibold !text-dark after:!hidden" disabled={disabled.cat9} >
              <span className="text-medium text-dark-semilight uppercase">{t("formulario.titleCat9")}</span>
              <div className="leading-[22px] faq-arrow">
              {infoFormularioCategoria.ResultatCat9Final ==null?
              cat !=9?
                <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.0466659 0.744778L3.86978 5.53751C3.93301 5.61663 4.06672 5.61663 4.12995 5.53751L7.95348 0.744445C7.99899 0.68712 8.01247 0.601674 7.98793 0.527294C7.98285 0.511735 7.97719 0.499838 7.97245 0.490935C7.94325 0.436855 7.88717 0.403076 7.8256 0.403076H0.174128C0.112893 0.403076 0.0564835 0.436772 0.02753 0.490935C0.0226212 0.500004 0.0169636 0.511985 0.0122212 0.526378C-0.0124892 0.601674 0.000906017 0.687287 0.0466659 0.744778Z" fill="#e20078" />
                </svg>
              : <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.0466659 0.744778L3.86978 5.53751C3.93301 5.61663 4.06672 5.61663 4.12995 5.53751L7.95348 0.744445C7.99899 0.68712 8.01247 0.601674 7.98793 0.527294C7.98285 0.511735 7.97719 0.499838 7.97245 0.490935C7.94325 0.436855 7.88717 0.403076 7.8256 0.403076H0.174128C0.112893 0.403076 0.0564835 0.436772 0.02753 0.490935C0.0226212 0.500004 0.0169636 0.511985 0.0122212 0.526378C-0.0124892 0.601674 0.000906017 0.687287 0.0466659 0.744778Z" fill="#3EC37D"></path>
                </svg>
              :infoFormularioCategoria.ResultatCat9Final !=null?<div className="leading-[22px] faq-arrow"><p className="text-[22px] font-semibold text-green">{infoFormularioCategoria.ResultatCat9Final}</p></div>
              :null}
              </div>
              </button>
        {cat ==9 && infoFormularioCategoria.ResultatCat9Final ==null ?<FormularioCat9 />
        : null}
        </h2>
        </div>

        </div>
        :null}

    </>
  );
};
