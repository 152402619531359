import React from 'react'
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addFormularioCat1, addFormularioCat5, addProgramaCat5 } from '../../actions/configFormularioAction';
import { crearErrorAction } from '../../actions/ui';
import { calculoPorcentaje } from '../../helpers/FormulasCat';
import { useForm } from "../../hooks/useForm";
import { useTranslation } from "react-i18next";

export const FormularioCat5 = () => {
  const dispatch = useDispatch(); 
  const [t, i18n] = useTranslation("generic");
  const state = useSelector((state) => state);
  const { msgError } = useSelector((state) => state.ui);
  
  const { auth,configFormulario} = useSelector(state => state);
  const [formValues, handleInputChange,reset] = useForm({
    cat5_1: "",
    cat5_2: "",
    cat5_3: "",
    cat5_4: "",
    cat5_5: "",
    cat5_6: "",
    cat5_7: "",
    cat5_8: "",
    cat5_9: "",
    cat5_10: "",
    cat5_11: "",
    cat5_12: "",
    cat5_13: "",
    
});

const { cat5_1, cat5_2, cat5_3, cat5_4, cat5_5, cat5_6,cat5_7,cat5_8,cat5_9,cat5_10,cat5_11,cat5_12,cat5_13} = formValues;

  const handleCalcularCat = (e) => {
    
    if(validarFormulas()){
      
      dispatch(addFormularioCat5(auth.tokenJwt,cat5_1, cat5_2, cat5_3, cat5_4, cat5_5, cat5_6,cat5_7,cat5_8,cat5_9,cat5_10,cat5_11,cat5_12,cat5_13,configFormulario.infoFormulario.IdEvaluacion));
      
      reset()

    }else{
      console.log("out");
      //setMsgError("")
    }
  };

  const validarFormulas = () => {
    let esCorrecte = true;
    let msgError ="";
    if(isNaN(cat5_1) || !cat5_1 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 5.1 + t("formulario.msgError2");}
    if(isNaN(cat5_2) || !cat5_2 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 5.2 + t("formulario.msgError2");}
    if(isNaN(cat5_3) || !cat5_3 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 5.3 + t("formulario.msgError2");}
    if(isNaN(cat5_4) || !cat5_4 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 5.4 + t("formulario.msgError2");}
    if(isNaN(cat5_5) || !cat5_5 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 5.5 + t("formulario.msgError2");}
    if(isNaN(cat5_6) || !cat5_6 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 5.6 + t("formulario.msgError2");}
    if(isNaN(cat5_7) || !cat5_7 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 5.7 + t("formulario.msgError2");}
    if(isNaN(cat5_8) || !cat5_8 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 5.8 + t("formulario.msgError2");}
    if(isNaN(cat5_9) || !cat5_9 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 5.9 + t("formulario.msgError2");}
    if(isNaN(cat5_10) || !cat5_10 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 5.10 + t("formulario.msgError2");}
    if(isNaN(cat5_11) || !cat5_11 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 5.11 + t("formulario.msgError2");}
    if(isNaN(cat5_12) || !cat5_12 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 5.12 + t("formulario.msgError2");}
    if(isNaN(cat5_13) || !cat5_13  ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 5.13 + t("formulario.msgError2");}

    
    if(!esCorrecte){
      dispatch(crearErrorAction(msgError));
    }
    return esCorrecte;
  }
  const handleCalcularPrograma = (e) => {
    if(validarProgramas()){ 
      dispatch(addProgramaCat5(auth.tokenJwt,cat5_1, cat5_2,cat5_3,cat5_4,configFormulario.infoFormulario.IdEvaluacion));
    }else{
      console.log("out");
    }
  };
  const validarProgramas = () => {
    let esCorrecte = true;
    let msgError ="";
    if(isNaN(cat5_1) || !cat5_1 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 5.1 + t("formulario.msgError2");}
    if(isNaN(cat5_2) || !cat5_2 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 5.2 + t("formulario.msgError2");} 
    if(isNaN(cat5_3) || !cat5_3 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 5.3 + t("formulario.msgError2");}
    if(isNaN(cat5_4) || !cat5_4 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 5.4 + t("formulario.msgError2");}
    if(!esCorrecte){
      dispatch(crearErrorAction(msgError));
    }
    return esCorrecte;
  }
  return (
    <>
    <div className='ac-panel'>
      <div className="ac-text !p-9 !pt-3 !text-[15px] !font-normal !leading-7 !text-dark-light">
        {msgError != null  ? ( //això es una condició, si es diferent de null
                      <div className="alert alert-danger">{msgError}</div>
                    ) : null}
                    {configFormulario.infoFormularioCategoria.ResultatCat5==null?
    <>
        <div className='sm:col-span-2'>
          <label htmlFor="cat5_1">{t("Categoria5.preg1")}</label>
          <select id={cat5_1} name="cat5_1" defaultValue="" className='form-inputCat' onChange={handleInputChange}>
            <option value=''>{t("CategoriaDesplegable.seleccionar")}</option>
            <option value="1">{t("CategoriaDesplegable.5.1_1")} </option>
            <option value="0">{t("CategoriaDesplegable.5.1_0")} </option>
          </select>
        </div>
        <div className='sm:col-span-2'>
          <label htmlFor="cat5_2"> {t("Categoria5.preg2")}</label>
          <input type="text" className='form-inputCat' name="cat5_2" id="cat5_2"  onChange={handleInputChange} required/>
        </div>
        <div className='sm:col-span-2'>
          <label htmlFor="cat5_3"> {t("Categoria5.preg3")}</label>
          <input type="text" className='form-inputCat' name="cat5_3" id="cat5_3"  onChange={handleInputChange} required/>
        </div>
        <div className='sm:col-span-2'>
          <label htmlFor="cat5_4">{t("Categoria5.preg4")} </label>
          <input type="text" className='form-inputCat' name="cat5_4" id="cat5_4"  onChange={handleInputChange} required/>
        </div>
        <div className='sm:col-span-2'>
          <label htmlFor="cat5_5">{t("Categoria5.preg5")} </label>
          <input type="text" className='form-inputCat' name="cat5_5" id="cat5_5"  onChange={handleInputChange} required/>
        </div>
        <div className='sm:col-span-2'>
          <label htmlFor="cat5_6">{t("Categoria5.preg6")}</label>
          <input type="text" className='form-inputCat' name="cat5_6" id="cat5_6"  onChange={handleInputChange} required/>
        </div>
        <div className='sm:col-span-2'>
          <label htmlFor="cat5_7">{t("Categoria5.preg7")} </label>
          <input type="text" className='form-inputCat'  name="cat5_7" id="cat5_7"  onChange={handleInputChange} required/>
        </div>
        <div className='sm:col-span-2'>
          <label htmlFor="cat5_8">{t("Categoria5.preg8")}</label>
          <input type="text" className='form-inputCat' name="cat5_8" id="cat5_8"  onChange={handleInputChange} required/>
        </div>
        <div className='sm:col-span-2'>
          <label htmlFor="cat5_9">{t("Categoria5.preg9")} </label>
          <select className='form-inputCat' id={cat5_9} name="cat5_9" value={cat5_9} onChange={handleInputChange}>
            <option value=''>{t("CategoriaDesplegable.seleccionar")}</option>
            <option value="2">{t("CategoriaDesplegable.5AnyAnterior_2")} </option>
            <option value="1">{t("CategoriaDesplegable.5AnyAnterior_1")} </option>
            <option value="0">{t("CategoriaDesplegable.5AnyAnterior_0")}</option>
          </select> 
        </div>
        <div className='sm:col-span-2'>
          <label htmlFor="cat5_10">{t("Categoria5.preg10")}</label>
          <select className='form-inputCat' id={cat5_10} name="cat5_10" value={cat5_10} onChange={handleInputChange}>
            <option value=''>{t("CategoriaDesplegable.seleccionar")}</option>
            <option value="2">{t("CategoriaDesplegable.5AnyAnterior_2")} </option>
            <option value="1">{t("CategoriaDesplegable.5AnyAnterior_1")} </option>
            <option value="0">{t("CategoriaDesplegable.5AnyAnterior_0")}</option>
          </select>         </div>
        <div className='sm:col-span-2'>
          <label htmlFor="cat5_11">{t("Categoria5.preg11")}</label>
          <select className='form-inputCat' id={cat5_11} name="cat5_11" value={cat5_11} onChange={handleInputChange}>
            <option value=''>{t("CategoriaDesplegable.seleccionar")}</option>
            <option value="2">{t("CategoriaDesplegable.5AnyAnterior_2")} </option>
            <option value="1">{t("CategoriaDesplegable.5AnyAnterior_1")} </option>
            <option value="0">{t("CategoriaDesplegable.5AnyAnterior_0")}</option>
          </select>         </div>
        <div className='sm:col-span-2'>
          <label htmlFor="cat5_12">{t("Categoria5.preg12")}</label>
          <select className='form-inputCat' id={cat5_12} name="cat5_12" value={cat5_12} onChange={handleInputChange}>
            <option value=''>{t("CategoriaDesplegable.seleccionar")}</option>
            <option value="2">{t("CategoriaDesplegable.5AnyAnterior_2")} </option>
            <option value="1">{t("CategoriaDesplegable.5AnyAnterior_1")} </option>
            <option value="0">{t("CategoriaDesplegable.5AnyAnterior_0")}</option>
          </select>         </div>
        <div className='sm:col-span-2'>
          <label htmlFor="cat5_13">{t("Categoria5.preg13")} </label>
          <input type="text" className='form-inputCat' name="cat5_13" id="cat5_13"  onChange={handleInputChange} required/>
        </div>


        <div><button onClick={handleCalcularCat} id='cat1' className="btn-turquesa">{t("formulario.enviar")}</button></div>
        </>
      :<>
      <div className='sm:col-span-2'>
          <label htmlFor="cat5_1">{t("CategoriaPrograma.5_1")} </label>
          <select className='form-inputCat' id={cat5_1} name="cat5_1" value={cat5_1} onChange={handleInputChange}>
            <option value=''>{t("CategoriaDesplegable.seleccionar")}</option>
            <option value="2">{t("CategoriaDesplegable.si")} </option>
            <option value="1">{t("CategoriaDesplegable.mitat")} </option>
            <option value="0">{t("CategoriaDesplegable.no")}</option>
          </select>        </div>
        <div className='sm:col-span-2'>
          <label htmlFor="cat5_2">{t("CategoriaPrograma.5_2")} </label>
          <select className='form-inputCat' id={cat5_2} name="cat5_2" value={cat5_2} onChange={handleInputChange}>
            <option value=''>{t("CategoriaDesplegable.seleccionar")}</option>
            <option value="2">{t("CategoriaDesplegable.si")} </option>
            <option value="1">{t("CategoriaDesplegable.mitat")} </option>
            <option value="0">{t("CategoriaDesplegable.no")}</option>
          </select>        </div>
        <div className='sm:col-span-2'>
          <label htmlFor="cat5_3">{t("CategoriaPrograma.5_3")} </label>
          <select className='form-inputCat' id={cat5_3} name="cat5_3" value={cat5_3} onChange={handleInputChange}>
            <option value=''>{t("CategoriaDesplegable.seleccionar")}</option>
            <option value="2">{t("CategoriaDesplegable.si")} </option>
            <option value="1">{t("CategoriaDesplegable.mitat")} </option>
            <option value="0">{t("CategoriaDesplegable.no")}</option>
          </select>        </div>
        <div className='sm:col-span-2'>
          <label htmlFor="cat5_4">{t("CategoriaPrograma.5_4")} </label>
          <select className='form-inputCat' id={cat5_4} name="cat5_4" value={cat5_4} onChange={handleInputChange}>
            <option value=''>{t("CategoriaDesplegable.seleccionar")}</option>
            <option value="2">{t("CategoriaDesplegable.si")} </option>
            <option value="1">{t("CategoriaDesplegable.mitat")} </option>
            <option value="0">{t("CategoriaDesplegable.no")}</option>
          </select>        </div>
    <div><button onClick={handleCalcularPrograma} id='cat1' className="btn-turquesa">{t("formulario.enviar")}</button></div>
    </> 
    }
      </div>
    </div>
    </>
  )
}
