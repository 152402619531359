import React from 'react'
import { useSelector } from 'react-redux';
import { uiAmagarCalendarModal } from '../../actions/ui';

export const LogoCapcaleraGlobal = () => {
  const { auth,ui } = useSelector(state => state);
    return (
      <div className="flex items-center justify-between">

          <a href="../"><img src="assets/images/logo.jpg" className="" alt="Ciudadesquecuidan"/></a>

      </div>
    )
}
