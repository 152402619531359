import { types } from "../types/types";

export const configUsuariReducer = (state = {},action) => {

    switch (action.type){
        case types.configUsuariRecuperarInfo:
            return {...state,
                
                iniciat : action.iniciada
            
        }
            break;
        case types.configUsuariRecuperarInfoFinalitzada:
        return {...state,
            
            finalitzat : action.finalitzada,
            contingut : action.contingut
            }
        case types.configUsuariRecuperarInfoFinalitzada:
            return {...state,
                    
                finalitzat : action.finalitzada,
                contingut : action.contingut
                }
        default:
            return state;
    }

}