import React from 'react'
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addFormularioCat1, addFormularioCat9, addProgramaCat9, infoCategoria } from '../../actions/configFormularioAction';
import { crearErrorAction } from '../../actions/ui';
import { calculoPorcentaje } from '../../helpers/FormulasCat';
import { useForm } from "../../hooks/useForm";
import { useTranslation } from "react-i18next";

export const FormularioCat9 = () => {
  const dispatch = useDispatch(); 
  
  const state = useSelector((state) => state);
  const { msgError } = useSelector((state) => state.ui);
  const [t, i18n] = useTranslation("generic");
  const { auth,configFormulario} = useSelector(state => state);
  const {infoFormulario,infoFormularioCategoria} = configFormulario;
  const [formValues, handleInputChange,reset] = useForm({
    cat9_1: "",
    cat9_2: "",
    cat9_3: "",
    cat9_4: "", 
});

const { cat9_1, cat9_2, cat9_3, cat9_4} = formValues;

  const handleCalcularCat = (e) => {
    if(validarFormulas()){
      
      dispatch(addFormularioCat9(auth.tokenJwt,cat9_1, cat9_2, cat9_3, cat9_4,configFormulario.infoFormulario.IdEvaluacion));
      reset();
    }else{
      console.log("out");
      //setMsgError("")
    }
  };
  const handleCalcularPrograma = (e) => {
    if(validarProgramas()){ 
      dispatch(addProgramaCat9(auth.tokenJwt,cat9_1, cat9_2,configFormulario.infoFormulario.IdEvaluacion));
    }else{
      console.log("out");
    }
  };
  const validarProgramas = () => {
    let esCorrecte = true;
    let msgError ="";
    if(isNaN(cat9_1) || !cat9_1 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 9.1 + t("formulario.msgError2");}
    if(isNaN(cat9_2) || !cat9_2 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 9.2 + t("formulario.msgError2");} 
    if(!esCorrecte){
      dispatch(crearErrorAction(msgError));
    }
    return esCorrecte;
  }

  const validarFormulas = () => {
    let esCorrecte = true;
    let msgError ="";
    if(isNaN(cat9_1) || !cat9_1 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 9.1 + t("formulario.msgError2");}
    if(isNaN(cat9_2) || !cat9_2 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 9.2 + t("formulario.msgError2");}
    if(isNaN(cat9_3) || !cat9_3 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 9.3 + t("formulario.msgError2");}
    if(isNaN(cat9_4) || !cat9_4 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 9.4 + t("formulario.msgError2");}
    
    if(!esCorrecte){
      dispatch(crearErrorAction(msgError));
    }
    return esCorrecte;
  }
  

  return (
    <>
    <div className='ac-panel'>
      <div className="ac-text !p-9 !pt-3 !text-[15px] !font-normal !leading-7 !text-dark-light">
        {msgError != null  ? ( //això es una condició, si es diferent de null
                      <div className="alert alert-danger">{msgError}</div>
                    ) : null}
                    {configFormulario.infoFormularioCategoria.ResultatCat9==null?
    <>
        <div className='sm:col-span-2'>
          <label htmlFor="cat9_1">{t("Categoria9.preg1")} </label>
          <input className='form-inputCat' type="text" name="cat9_1" id="cat9_1"  onChange={handleInputChange} required/>
        </div>
        <div className='sm:col-span-2'>
          <label htmlFor="cat9_2">{t("Categoria9.preg2")}</label>
          <input className='form-inputCat' type="text" name="cat9_2" id="cat9_2"  onChange={handleInputChange} required/>
        </div>
        <div className='sm:col-span-2'>
          <label htmlFor="cat9_3">{t("Categoria9.preg3")}</label>
          <select className='form-inputCat' id={cat9_3} name="cat9_3" defaultValue="" onChange={handleInputChange}>
            <option value=''>{t("CategoriaDesplegable.seleccionar")}</option>
            <option value="3">{t("CategoriaDesplegable.9.X_3")}</option>
            <option value="2">{t("CategoriaDesplegable.9.X_2")} </option>
            <option value="1">{t("CategoriaDesplegable.9.X_1")} </option>
            <option value="0">{t("CategoriaDesplegable.9.X_0")}</option>
          </select>
        </div>
        <div className='sm:col-span-2'>
          <label htmlFor="cat9_4">{t("Categoria9.preg4")}</label>
          <select className='form-inputCat' id={cat9_4} name="cat9_4" defaultValue="" onChange={handleInputChange}>
            <option value=''>{t("CategoriaDesplegable.seleccionar")}</option>
            <option value="3">{t("CategoriaDesplegable.9.X_3")}</option>
            <option value="2">{t("CategoriaDesplegable.9.X_2")} </option>
            <option value="1">{t("CategoriaDesplegable.9.X_1")} </option>
            <option value="0">{t("CategoriaDesplegable.9.X_0")}</option>
          </select>
        </div>
        


        <div><button onClick={handleCalcularCat} id='cat1' className="btn-turquesa">{t("formulario.enviar")}</button></div>
        </>
    :<>
    <div className='sm:col-span-2'>
          <label htmlFor="cat9_1">{t("CategoriaPrograma.9_1")} </label>
          <select className='form-inputCat' id={cat9_1} name="cat9_1" value={cat9_1} onChange={handleInputChange}>
            <option value=''>{t("CategoriaDesplegable.seleccionar")}</option>
            <option value="2">{t("CategoriaDesplegable.si")} </option>
            <option value="1">{t("CategoriaDesplegable.mitat")} </option>
            <option value="0">{t("CategoriaDesplegable.no")}</option>
          </select>        </div>
        <div className='sm:col-span-2'>
          <label htmlFor="cat9_2">{t("CategoriaPrograma.9_2")} </label>
          <select className='form-inputCat' id={cat9_2} name="cat9_2" value={cat9_2} onChange={handleInputChange}>
            <option value=''>{t("CategoriaDesplegable.seleccionar")}</option>
            <option value="2">{t("CategoriaDesplegable.si")} </option>
            <option value="1">{t("CategoriaDesplegable.mitat")} </option>
            <option value="0">{t("CategoriaDesplegable.no")}</option>
          </select>
        </div>
    <div><button onClick={handleCalcularPrograma} id='cat1' className="btn-turquesa">{t("formulario.enviar")}</button></div>
    </> 
    }
      </div>
      </div>
    </>
  )
}
