import React, { useEffect, useState } from 'react'
import { useDispatch,useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import { iniciDesloginar } from '../../actions/auth'
import { renovarTokenAction } from '../../actions/auth';
import { useTranslation } from 'react-i18next';
import { recuperarConfigUsuari } from '../../actions/configUsuariAction';
import { uiModalUserAction } from '../../actions/ui';


export const UsuariLoginat = () => {
    const dispatch = useDispatch();
    const navegacio = useNavigate();
    const { auth,ui } = useSelector(state => state);
    const { formulari,resultatsID} = useSelector((state) => state.ui);
    const [t, i18n] = useTranslation('generic');
    const [contadorRenovarToken, setContadorRenovarToken] = useState(new Date());


    useEffect(() => {
      if(auth.tokenJwt!== undefined){
        dispatch(recuperarConfigUsuari(auth.tokenJwt)); 
        }else{
          navegacio('/login',{replace:true})
        }
      }, [auth]);

      useEffect(() => {
        //S'executa cada 5 minuts per renovar el token Auth
        const interval = setInterval(
          () => setContadorRenovarToken(new Date()),
          60000*5
          // 60000*5
        );
          
        dispatch(renovarTokenAction(auth.tokenJwt));  
        return () => {
          clearInterval(interval);
        }
      }, [contadorRenovarToken]);


const desloginarUsuari = () =>{
    dispatch(iniciDesloginar());
    
    navegacio('/login',{replace:true})
}
const modificarUser =()=>{
  dispatch( uiModalUserAction(true) );
  
}

    return (

        <ul className="lg:space-x-8 space-y-4 lg:space-y-0 lg:flex items-center mt-6 lg:mt-0 max-[1024px]:max-h-80 max-[1024px]:overflow-scroll">
          <li> {auth.admin ==0 ?
             <p className="text-green uppercase font-medium"><i className="bi-person-circle me-1"></i> { auth.nombre + " " + auth.apellido }  </p>
            :<p className="text-green uppercase font-medium">(ADMIN) <i className="bi-person-circle me-1"></i>{ auth.nombre + " " + auth.apellido }  </p>
             }
             {!formulari && resultatsID ==null?      
             <button  type="button" onClick={modificarUser}  value="Modificar perfil" name="boton" >{t("login.titleModificar")}</button> 
            :null}
            </li>
             <li>
             <button className="btn-lila" onClick={ desloginarUsuari } ><i className="bi bi-box-arrow-right me-2"></i>{t("login.txtCerrarSesion")}</button> </li>
         </ul>
    )
} 