import React from 'react'
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addFormularioCat1, addProgramaCat1 } from '../../actions/configFormularioAction';
import { crearErrorAction } from '../../actions/ui';
import { calculoPorcentaje } from '../../helpers/FormulasCat';
import { useForm } from "../../hooks/useForm";
import { useTranslation } from "react-i18next";


export const FormularioCat1 = () => {
  const dispatch = useDispatch(); 
  const [t, i18n] = useTranslation("generic");
  const state = useSelector((state) => state);
  const { msgError } = useSelector((state) => state.ui);
  
  const { auth,configFormulario} = useSelector(state => state);
  const [formValues, handleInputChange,reset] = useForm({
    cat1_1: "",
    cat1_2: "",
    cat1_3: "",
    cat1_4: "",
    cat1_5: "",
    cat1_6: "",
    cat1_7: "",
    
});

const { cat1_1, cat1_2, cat1_3, cat1_4, cat1_5, cat1_6,cat1_7} = formValues;

  const handleCalcularCat = (e) => {
    
    if(validarFormulas()){
      
      dispatch(addFormularioCat1(auth.tokenJwt,cat1_1, cat1_2, cat1_3, cat1_4, cat1_5, cat1_6,cat1_7,configFormulario.infoFormulario.IdEvaluacion));
      reset();
      

    }else{
      console.log("out");
      //setMsgError("")
    }
  };

  const validarFormulas = () => {
    let esCorrecte = true;
    let msgError ="";
    if(isNaN(cat1_1) || !cat1_1 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 1.1 + t("formulario.msgError2");}
    if(isNaN(cat1_2) || !cat1_2 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 1.2 + t("formulario.msgError2");}
    if(isNaN(cat1_3) || !cat1_3 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 1.3 + t("formulario.msgError2");}
    if(isNaN(cat1_4) || !cat1_4 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 1.4 + t("formulario.msgError2");}
    if(isNaN(cat1_5) || !cat1_5 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 1.5 + t("formulario.msgError2");}
    if(isNaN(cat1_6) || !cat1_6 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 1.6 + t("formulario.msgError2");}
    if(isNaN(cat1_7) || !cat1_7 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 1.7 + t("formulario.msgError2");}

    
    if(!esCorrecte){
      dispatch(crearErrorAction(msgError));
    }
    return esCorrecte;
  }
  const handleCalcularPrograma = (e) => {
    if(validarProgramas()){ 
      dispatch(addProgramaCat1(auth.tokenJwt,cat1_1, cat1_2, cat1_3, cat1_4, cat1_5,configFormulario.infoFormulario.IdEvaluacion));
    }else{
      console.log("out");
    }
  };
  const validarProgramas = () => {
    let esCorrecte = true;
    let msgError ="";
    if(isNaN(cat1_1) || !cat1_1 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 1.1 + t("formulario.msgError2");}
    if(isNaN(cat1_2) || !cat1_2 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 1.2 + t("formulario.msgError2");}
    if(isNaN(cat1_3) || !cat1_3 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 1.3 + t("formulario.msgError2");}
    if(isNaN(cat1_4) || !cat1_4 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 1.4 + t("formulario.msgError2");}
    if(isNaN(cat1_5) || !cat1_5 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 1.5 + t("formulario.msgError2");}
    if(!esCorrecte){
      dispatch(crearErrorAction(msgError));
    }
    return esCorrecte;
  }
  return (
    <>
    <div className='ac-panel'>
     <div className="ac-text !p-9 !pt-3 !text-[15px] !font-normal !leading-7 !text-dark-light">
     {msgError != null  ? ( //això es una condició, si es diferent de null
                  <div className="alert alert-danger">{msgError}</div>
                ) : null}
    {configFormulario.infoFormularioCategoria.ResultatCat1==null?
    <>
        <div className='sm:col-span-2'>
          <label htmlFor="cat1_1">{t("Categoria1.preg1")}</label>
          <input type="text" className='form-inputCat' name="cat1_1" id="cat1_1"  onChange={handleInputChange} required/>
          </div>
        <div className='sm:col-span-2'>
          <label htmlFor="cat1_2">{t("Categoria1.preg2")}</label>
          <input type="text" className='form-inputCat' name="cat1_2" id="cat1_2"  onChange={handleInputChange} required/>
          </div>
        <div className='sm:col-span-2'>
          <label htmlFor="cat1_3">{t("Categoria1.preg3")} </label>
          <input type="text" className='form-inputCat' name="cat1_3" id="cat1_3"  onChange={handleInputChange} required/>
          </div>
        <div className='sm:col-span-2'>
          <label htmlFor="cat1_4">{t("Categoria1.preg4")} </label>
          <input type="text" className='form-inputCat' name="cat1_4" id="cat1_4"  onChange={handleInputChange} required/>
          </div>
        <div className='sm:col-span-2'>
          <label htmlFor="cat1_5">{t("Categoria1.preg5")}</label>
          <input type="text" className='form-inputCat' name="cat1_5" id="cat1_5"  onChange={handleInputChange} required/>
          </div>
        <div className='sm:col-span-2'>
          <label htmlFor="cat1_6">{t("Categoria1.preg6")} </label>
          <input type="text" className='form-inputCat' name="cat1_6" id="cat1_6"  onChange={handleInputChange} required/>
          </div>
        <div className='sm:col-span-2'>
          <label htmlFor="cat1_7">{t("Categoria1.preg7")}</label>
          <input type="text" className='form-inputCat' name="cat1_7" id="cat1_7"  onChange={handleInputChange} required/>
          </div>


    <div><button onClick={handleCalcularCat} id='cat1' className="btn-turquesa">{t("formulario.enviar")}</button></div>
    </>
    :<>
       <div className='sm:col-span-2'>
         <label htmlFor="cat1_1">{t("CategoriaPrograma.1_1")}  </label>
         <select className='form-inputCat' id={cat1_1} name="cat1_1" value={cat1_1} onChange={handleInputChange}>
           <option value=''>{t("CategoriaDesplegable.seleccionar")}</option>
           <option value="2">{t("CategoriaDesplegable.si")} </option>
           <option value="1">{t("CategoriaDesplegable.mitat")} </option>
           <option value="0">{t("CategoriaDesplegable.no")}</option>
         </select>
       </div>
       <div className='sm:col-span-2'>
         <label htmlFor="cat1_2">{t("CategoriaPrograma.1_2")} </label>
         <select className='form-inputCat' id={cat1_2} name="cat1_2" value={cat1_2} onChange={handleInputChange}>
           <option value=''>{t("CategoriaDesplegable.seleccionar")}</option>
           <option value="2">{t("CategoriaDesplegable.si")} </option>
           <option value="1">{t("CategoriaDesplegable.mitat")} </option>
           <option value="0">{t("CategoriaDesplegable.no")}</option>
         </select>
       </div>
       <div className='sm:col-span-2'>
         <label htmlFor="cat1_3">{t("CategoriaPrograma.1_3")}  </label>
         <select className='form-inputCat' id={cat1_3} name="cat1_3" value={cat1_3} onChange={handleInputChange}>
           <option value=''>{t("CategoriaDesplegable.seleccionar")}</option>
           <option value="2">{t("CategoriaDesplegable.si")} </option>
           <option value="1">{t("CategoriaDesplegable.mitat")} </option>
           <option value="0">{t("CategoriaDesplegable.no")}</option>
         </select>
       </div>
       <div className='sm:col-span-2'>
         <label htmlFor="cat1_4">{t("CategoriaPrograma.1_4")}  </label>
         <select className='form-inputCat' id={cat1_4} name="cat1_4" value={cat1_4} onChange={handleInputChange}>
           <option value=''>{t("CategoriaDesplegable.seleccionar")}</option>
           <option value="2">{t("CategoriaDesplegable.si")} </option>
           <option value="1">{t("CategoriaDesplegable.mitat")} </option>
           <option value="0">{t("CategoriaDesplegable.no")}</option>
         </select>
       </div>
       <div className='sm:col-span-2'>
         <label htmlFor="cat1_5">{t("CategoriaPrograma.1_5")} </label>
         <select className='form-inputCat' id={cat1_5} name="cat1_5" value={cat1_5} onChange={handleInputChange}>
           <option value=''>{t("CategoriaDesplegable.seleccionar")}</option>
           <option value="2">{t("CategoriaDesplegable.si")} </option>
           <option value="1">{t("CategoriaDesplegable.mitat")} </option>
           <option value="0">{t("CategoriaDesplegable.no")}</option>
         </select>
       </div>
       <div><button onClick={handleCalcularPrograma} id='cat1' className="btn-turquesa">{t("formulario.enviar")}</button></div>
    </>
    
    }
    </div>
    </div>
    </>
  )
}
