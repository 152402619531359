import React from 'react'

import { LogoCapcaleraGlobal } from './LogoCapcaleraGlobal'

export const HeaderPublic = () => {

    return (
      
      <header className="main-menu bg-white">
      <nav className="top-0 w-full z-50 mobile-menu-bg">
          <div className="container">
              <div className="lg:flex items-center justify-between gap-6">
              <LogoCapcaleraGlobal />  
              </div>
             
              
          </div>
      </nav>
      </header>
        
    )
}
