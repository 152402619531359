import React from 'react'
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addFormularioCat1, addFormularioCat2, addProgramaCat2, infoCategoria } from '../../actions/configFormularioAction';
import { crearErrorAction } from '../../actions/ui';
import { useForm } from "../../hooks/useForm";
import { useTranslation } from "react-i18next";


export const FormularioCat2 = () => {

  const dispatch = useDispatch(); 
  const [t, i18n] = useTranslation("generic");
  const state = useSelector((state) => state);
  const { msgError } = useSelector((state) => state.ui);
  
  const { auth,configFormulario} = useSelector(state => state);
  const [formValues, handleInputChange,reset] = useForm({
    cat2_1: "",
    cat2_2: "",
    cat2_3: "",
    cat2_4: "",
    cat2_5: "",
    cat2_6: "",
    cat2_7: "",
    cat2_8: "",
    cat2_9: "",
    cat2_10: "",
    cat2_11: "",
    cat2_12: "",
    cat2_13: "",
    cat2_14: "",
    cat2_15: "",
    cat2_16: "",
    cat2_17: "",
    cat2_18: "",  
});

const { cat2_1, cat2_2, cat2_3, cat2_4, cat2_5, cat2_6,cat2_7,cat2_8,cat2_9,cat2_10,cat2_11,cat2_12,cat2_13,cat2_14,cat2_15,cat2_16,cat2_17,cat2_18} = formValues;

  const handleCalcularCat = (e) => {
    
    if(validarFormulas()){
      
      dispatch(addFormularioCat2(auth.tokenJwt,cat2_1, cat2_2, cat2_3, cat2_4, cat2_5, cat2_6,cat2_7,cat2_8,cat2_9,cat2_10,cat2_11,cat2_12,cat2_13,cat2_14,cat2_15,cat2_16,cat2_17,cat2_18,configFormulario.infoFormulario.IdEvaluacion));
      //reset();
      //dispatch(infoCategoria(auth.tokenJw,configFormulario.infoFormulario.IdEvaluacion))
      

    }else{
      console.log("out");
      //setMsgError("")
    }
  };

  const validarFormulas = () => {
    let esCorrecte = true;
    let msgError ="";
    if(isNaN(cat2_1) || !cat2_1 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 2.1 + t("formulario.msgError2");}
    if(isNaN(cat2_2) || !cat2_2 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 2.2 + t("formulario.msgError2");}
    if(isNaN(cat2_3) || !cat2_3 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 2.3 + t("formulario.msgError2");}
    if(isNaN(cat2_4) || !cat2_4 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 2.4 + t("formulario.msgError2");}
    if(isNaN(cat2_5) || !cat2_5 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 2.5 + t("formulario.msgError2");}
    if(isNaN(cat2_6) || !cat2_6 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 2.6 + t("formulario.msgError2");}
    if(isNaN(cat2_7) || !cat2_7 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 2.7 + t("formulario.msgError2");}
    if(isNaN(cat2_8) || !cat2_8 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 2.8 + t("formulario.msgError2");}
    if(isNaN(cat2_9) || !cat2_9 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 2.9 + t("formulario.msgError2");}
    if(isNaN(cat2_10) || !cat2_10 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 2.10 + t("formulario.msgError2");}
    if(isNaN(cat2_11) || !cat2_11 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 2.11 + t("formulario.msgError2");}
    if(isNaN(cat2_12) || !cat2_12 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 2.12 + t("formulario.msgError2");}
    if(isNaN(cat2_13) || !cat2_13 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 2.13 + t("formulario.msgError2");}

    
    if(!esCorrecte){
      dispatch(crearErrorAction(msgError));
    }
    return esCorrecte;
  }
  const handleCalcularPrograma = (e) => {
    if(validarProgramas()){ 
      dispatch(addProgramaCat2(auth.tokenJwt,cat2_1, cat2_2, cat2_3, cat2_4, cat2_5, cat2_6,cat2_7,configFormulario.infoFormulario.IdEvaluacion));
    }else{
      console.log("out");
    }
  };
  const validarProgramas = () => {
    let esCorrecte = true;
    let msgError ="";
    if(isNaN(cat2_1) || !cat2_1 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 2.1 + t("formulario.msgError2");}
    if(isNaN(cat2_2) || !cat2_2 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 2.2 + t("formulario.msgError2");}
    if(isNaN(cat2_3) || !cat2_3 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 2.3 + t("formulario.msgError2");}
    if(isNaN(cat2_4) || !cat2_4 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 2.4 + t("formulario.msgError2");}
    if(isNaN(cat2_5) || !cat2_5 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 2.5 + t("formulario.msgError2");}
    if(isNaN(cat2_6) || !cat2_6 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 2.6 + t("formulario.msgError2");}
    if(isNaN(cat2_7) || !cat2_7 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 2.7 + t("formulario.msgError2");}
    if(!esCorrecte){
      dispatch(crearErrorAction(msgError));
    }
    return esCorrecte;
  }
  return (
    <>
    <div className='ac-panel'>
     <div className="ac-text !p-9 !pt-3 !text-[15px] !font-normal !leading-7 !text-dark-light">
     {msgError != null  ? ( //això es una condició, si es diferent de null
                  <div className="alert alert-danger">{msgError}</div>
                ) : null}
    {configFormulario.infoFormularioCategoria.ResultatCat2==null?
    <>
        <div className='sm:col-span-2'>
          <label htmlFor="cat2_1">{t("Categoria2.preg1")}  </label>
          <input type="text" className='form-inputCat' name="cat2_1" id="cat2_1"  onChange={handleInputChange} required/>
        </div>
        <div className='sm:col-span-2'>
          <label htmlFor="cat2_2">{t("Categoria2.preg2")} </label>
          <input type="text" className='form-inputCat' name="cat2_2" id="cat2_2"  onChange={handleInputChange} required/>
        </div>
        <div className='sm:col-span-2'>
          <label htmlFor="cat2_3">{t("Categoria2.preg3")}  </label>
          <input type="text" className='form-inputCat' name="cat2_3" id="cat2_3"  onChange={handleInputChange} required/>
        </div>
        <div className='sm:col-span-2'>
          <label htmlFor="cat2_4">{t("Categoria2.preg4")}  </label>
          <input type="text" className='form-inputCat' name="cat2_4" id="cat2_4"  onChange={handleInputChange} required/>
        </div>
        <div className='sm:col-span-2'>
          <label htmlFor="cat2_5">{t("Categoria2.preg5")} </label>
          <input type="text" className='form-inputCat' name="cat2_5" id="cat2_5"  onChange={handleInputChange} required/>
        </div>
        <div className='sm:col-span-2'>
          <label htmlFor="cat2_6">{t("Categoria2.preg6")}  </label>
          <input type="text" className='form-inputCat' name="cat2_6" id="cat2_6"  onChange={handleInputChange} required/>
        </div>
        <div className='sm:col-span-2'>
          <label htmlFor="cat2_7">{t("Categoria2.preg7")} </label>
          <input type="text" className='form-inputCat' name="cat2_7" id="cat2_7"  onChange={handleInputChange} required/>
        </div>
        <div className='sm:col-span-2'>
          <label htmlFor="cat2_8">{t("Categoria2.preg8")}  </label>
          <input type="text" className='form-inputCat' name="cat2_8" id="cat2_8"  onChange={handleInputChange} required/>
        </div>
        <div className='sm:col-span-2'>
          <label htmlFor="cat2_9">{t("Categoria2.preg9")} </label>
          <input type="text" className='form-inputCat' name="cat2_9" id="cat2_9"  onChange={handleInputChange} required/>
        </div>
        <div className='sm:col-span-2'>
          <label htmlFor="cat2_10">{t("Categoria2.preg10")} </label>
          <input type="text" className='form-inputCat' name="cat2_10" id="cat2_10"  onChange={handleInputChange} required/>
        </div>
        <div className='sm:col-span-2'>
          <label htmlFor="cat2_11">{t("Categoria2.preg11")} </label>
          <input type="text" className='form-inputCat' name="cat2_11" id="cat2_11"  onChange={handleInputChange} required/>
        </div>
        <div className='sm:col-span-2'>
          <label htmlFor="cat2_12">{t("Categoria2.preg12")} </label>
          <input type="text" className='form-inputCat' name="cat2_12" id="cat2_12"  onChange={handleInputChange} required/>
        </div>
        <div className='sm:col-span-2'>
          <label htmlFor="cat2_13">{t("Categoria2.preg13")} </label>
          <input type="text" className='form-inputCat' name="cat2_13" id="cat2_13"  onChange={handleInputChange} required/>
        </div>
        <div className='sm:col-span-2'>
          <label htmlFor="cat2_14">{t("Categoria2.preg14")} </label>
          <input type="text" className='form-inputCat' name="cat2_14" id="cat2_14"  onChange={handleInputChange} required/>
        </div>
        <div className='sm:col-span-2'>
          <label htmlFor="cat2_15">{t("Categoria2.preg15")}  </label>
          <input type="text" className='form-inputCat' name="cat2_15" id="cat2_15"  onChange={handleInputChange} required/>
        </div>
        <div className='sm:col-span-2'>
          <label htmlFor="cat2_16">{t("Categoria2.preg16")}  </label>
          <input type="text" className='form-inputCat' name="cat2_16" id="cat2_16"  onChange={handleInputChange} required/>
        </div>
        <div className='sm:col-span-2'>
          <label htmlFor="cat2_17">{t("Categoria2.preg17")}  </label>
          <input type="text" className='form-inputCat' name="cat2_17" id="cat2_17"  onChange={handleInputChange} required/>
        </div>
        <div className='sm:col-span-2'>
          <label htmlFor="cat2_18">{t("Categoria2.preg18")} </label>
          <input type="text" className='form-inputCat' name="cat2_18" id="cat2_18"  onChange={handleInputChange} required/>
        </div>
    
        <div><button onClick={handleCalcularCat} id='cat1' className="btn-turquesa">{t("formulario.enviar")}</button></div>
        </>
    :<>
    <div className='sm:col-span-2'>
          <label htmlFor="cat2_1">{t("CategoriaPrograma.2_1")}  </label>
          <select className='form-inputCat' id={cat2_1} name="cat2_1" value={cat2_1} onChange={handleInputChange}>
            <option value=''>{t("CategoriaDesplegable.seleccionar")}</option>
            <option value="2">{t("CategoriaDesplegable.si")} </option>
            <option value="1">{t("CategoriaDesplegable.mitat")} </option>
            <option value="0">{t("CategoriaDesplegable.no")}</option>
          </select>
        </div>
        <div className='sm:col-span-2'>
          <label htmlFor="cat2_2">{t("CategoriaPrograma.2_2")} </label>
          <select className='form-inputCat' id={cat2_2} name="cat2_2" value={cat2_2} onChange={handleInputChange}>
            <option value=''>{t("CategoriaDesplegable.seleccionar")}</option>
            <option value="2">{t("CategoriaDesplegable.si")} </option>
            <option value="1">{t("CategoriaDesplegable.mitat")} </option>
            <option value="0">{t("CategoriaDesplegable.no")}</option>
          </select>
        </div>
        <div className='sm:col-span-2'>
          <label htmlFor="cat2_3">{t("CategoriaPrograma.2_3")}  </label>
          <select className='form-inputCat' id={cat2_3} name="cat2_3" value={cat2_3} onChange={handleInputChange}>
            <option value=''>{t("CategoriaDesplegable.seleccionar")}</option>
            <option value="2">{t("CategoriaDesplegable.si")} </option>
            <option value="1">{t("CategoriaDesplegable.mitat")} </option>
            <option value="0">{t("CategoriaDesplegable.no")}</option>
          </select>
        </div>
        <div className='sm:col-span-2'>
          <label htmlFor="cat2_4">{t("CategoriaPrograma.2_4")} </label>
          <select className='form-inputCat' id={cat2_4} name="cat2_4" value={cat2_4} onChange={handleInputChange}>
            <option value=''>{t("CategoriaDesplegable.seleccionar")}</option>
            <option value="2">{t("CategoriaDesplegable.si")} </option>
            <option value="1">{t("CategoriaDesplegable.mitat")} </option>
            <option value="0">{t("CategoriaDesplegable.no")}</option>
          </select>
        </div>
        <div className='sm:col-span-2'>
          <label htmlFor="cat2_5">{t("CategoriaPrograma.2_5")} </label>
          <select className='form-inputCat' id={cat2_5} name="cat2_5" value={cat2_5} onChange={handleInputChange}>
            <option value=''>{t("CategoriaDesplegable.seleccionar")}</option>
            <option value="2">{t("CategoriaDesplegable.si")} </option>
            <option value="1">{t("CategoriaDesplegable.mitat")} </option>
            <option value="0">{t("CategoriaDesplegable.no")}</option>
          </select>
        </div>
        <div className='sm:col-span-2'>
          <label htmlFor="cat2_6">{t("CategoriaPrograma.2_6")} </label>
          <select className='form-inputCat' id={cat2_6} name="cat2_6" value={cat2_6} onChange={handleInputChange}>
            <option value=''>{t("CategoriaDesplegable.seleccionar")}</option>
            <option value="2">{t("CategoriaDesplegable.si")} </option>
            <option value="1">{t("CategoriaDesplegable.mitat")} </option>
            <option value="0">{t("CategoriaDesplegable.no")}</option>
          </select>        </div>
        <div className='sm:col-span-2'>
          <label htmlFor="cat2_7">{t("CategoriaPrograma.2_7")} </label>
          <select className='form-inputCat' id={cat2_7} name="cat2_7" value={cat2_7} onChange={handleInputChange}>
            <option value=''>{t("CategoriaDesplegable.seleccionar")}</option>
            <option value="2">{t("CategoriaDesplegable.si")} </option>
            <option value="1">{t("CategoriaDesplegable.mitat")} </option>
            <option value="0">{t("CategoriaDesplegable.no")}</option>
          </select>        </div>
    <div><button onClick={handleCalcularPrograma} id='cat1' className="btn-turquesa">{t("formulario.enviar")}</button></div>
    </>
    
    }
      </div>
    </div>
    </>
  )
}
