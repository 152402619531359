import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useForm } from "../../hooks/useForm";
import isEmail from "validator/lib/isEmail";
import { esborrarErrorAction, crearErrorAction } from "../../actions/ui.js";
import { uiFinalitzarCarrega } from "../../types/types";
import { useTranslation } from "react-i18next";
import { iniciarPeticioLoginAsincronaAxios } from "../../actions/auth";

export const LoginScreen = (props) => {
  const dispatch = useDispatch(); //Per despachar accions
  const state = useSelector((state) => state); // Això retorna l'esta actual de l'aplicació, per poder accedir a tot el contingut emmagatzemat allà
  const { ui } = useSelector((state) => state); // Per desestructuració d¡objectes, podem agafar l'objecte desitjat del nostre state
  const { loading, msgError } = useSelector((state) => state.ui); //Per desestructuració, podem agafar propietats d'objectes directament
  const { auth } = useSelector((state) => state);
  //Farem servir useEffect. Es una eina de REact que permet executar una acció automàticament, afegint una condició de dependencia d'estat o no
  const [passwordShown, setPasswordShown] = useState(false);
  const [t, i18n] = useTranslation("generic");
  const [msgError2, setMsgError2] = useState(false);
  const navegacio = useNavigate();

  const [formValues, handleInputChange] = useForm({
    email: "",
    clau: "",
  });

  const { email, clau } = formValues;

  useEffect(() => {
    //Mirem si la propietat estaLoginat canvia. Si es així, redireccionem a la part privada
    if (auth.tokenJwt !== undefined) {
      navegacio("/");
      setMsgError2(false);
    }
  }, [auth]);

  const handleLogin = (e) => {
    e.preventDefault();
    if (isFormValid()) {
      setMsgError2(true);
      //Todo Cridar a API de login. SI tot es OK, crearem l'acció de Login amb les dades de l'usuari rebudes del servei
      dispatch(iniciarPeticioLoginAsincronaAxios(email,clau));
    } else {
    }
  };

//   const handleLogin2 = (e) => {
//     dispatch(esborrarErrorAction());
//     dispatch(iniciarPeticioLoginAsincronaAxios("german.guia@comb.cat","12345"));
// };
  const handleRegistreLogin = (e) => {
    e.preventDefault();
    navegacio("/login/register");
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (isFormValid()) {
        setMsgError2(true);
        //Todo Cridar a API de login. SI tot es OK, crearem l'acció de Login amb les dades de l'usuari rebudes del servei
         dispatch(iniciarPeticioLoginAsincronaAxios(email,clau));
      } else {
      }
    }
  };

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const isFormValid = () => {
    let esCorrecte = true;
    if (email.trim().length === 0 || clau.trim().lenght === 0) {
      setMsgError2(true);
      dispatch(crearErrorAction("Camps obligatoris"));
      esCorrecte = false;
    } else if (!isEmail(email)) {
      setMsgError2(true);
      dispatch(crearErrorAction("Email incorrecto"));
      esCorrecte = false;
    } else {
      setMsgError2(false);
      dispatch(esborrarErrorAction());
    }

    return esCorrecte;
  };

  return (
    <>
      <section className="md:py-[120px] py-10">
      <div className="container">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 items-center">
          <div className="text-center mt-12"><img src="assets/images/imatge2.jpg" className="rounded-full" alt="Ciudades que cuidan"/>
          
          </div>
          
          <div>
            <div className="lg:ml-auto">
              <p className="uppercase font-medium text-sm tracking-[0.84px] after:bg-green-semilight after:absolute after:w-full after:h-2 relative after:left-0 inline-block after:bottom-0 after:-z-10"></p>
              <h2 className="font-semibold mt-4 text-4xl leading-snug lg:text-5xl lg:leading-[62px]">{t("login.title")}</h2>

              <div className="mt-12">
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                  {msgError != null && msgError2 ? ( //això es una condició, si es diferent de null
                    <div className="alert alert-danger">{msgError}</div>
                  ) : null}

                  <div className="sm:col-span-2">
                <label className="form-label" htmlFor="signinSrEmail">
                    {t("login.txtEmail")}
                  </label>
                  <input
                      type="email"
                      value={email}
                      className="form-input"
                      name="email"
                      id="signinSrEmail"
                      tabIndex="1"
                      placeholder={t("login.txtEmail")}
                      aria-label="email@address.com"
                      onChange={handleInputChange}
                      required
                    />
                </div>


                <div className="sm:col-span-2">
                  <label
                    className="form-label"
                    htmlFor="signinSrPassword"
                    tabIndex="0"
                  >
                    {t("login.txtClau")}
                  </label>

                  <div className="input-group-merge">
                    <input
                      type={passwordShown ? "text" : "password"}
                      className="form-input"
                      name="clau"
                      id="signinSrPassword"
                      placeholder={t("login.txtClau")}
                      aria-label="8+ characters required"
                      onChange={handleInputChange}
                      required
                      minLength="8"
                      onKeyDown={handleKeyPress}
                      data-hs-toggle-password-options='{
                             "target": "#changePassTarget",
                             "defaultClass": "bi-eye-slash",
                             "showClass": "bi-eye",
                             "classChangeTarget": "#changePassIcon"
                           }'
                      value={clau}
                    />
                    <a
                      id="changePassTarget"
                      className="input-group-append input-group-text"
                      onClick={togglePassword}
                    >
                      <i id="changePassIcon" className="bi-eye"></i>
                    </a>
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <button
                    onClick={handleLogin}
                    className="btn-green w-full"
                    disabled={loading}
                  >
                    {t("login.btnIniciar")}
                  </button>
                </div>
                {/* <div className="sm:col-span-2">
                  <button
                    onClick={handleRegistreLogin}
                    className="btn-blau mt-12"
                    disabled={loading}
                  >
                    {t("login.btnLoginRegister")}
                  </button>
                </div> */}

              </div>
              </div>
            </div>

          
          </div>
        </div>
        
      </div>
      </section>
    </>
  );
};
