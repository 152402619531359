import { types } from "../types/types";

export const configFormularioReducer = (state = {},action) => {

    switch (action.type){
        
        // case types.configFormularioRecuperarConstantesFormulario:
        //     return {...state,
        //         constantesFormulario : action.contingut
        //         }
        // case types.configFormularioRecuperarIndicadoresFormulario:
        //     return {...state,
        //         indicadoresFormulario : action.contingut
        //         }
       case types.configFormularioInfo:
            return {...state,
                infoFormulario : action.contingut
                }
       case types.configFormularioCategoria:
            return {...state,
                infoFormularioCategoria : action.contingut
                }
        case types.configFormularioResultats:
            return {...state,
                resultatsCategoria : action.contingut
                }

        default:
            return state;
    }

}