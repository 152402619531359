export const types = {

    login : '[auth] Login',
    logout : '[auth] Logout',
    recuperarLogin: '[auth] Iniciar Recuperar Sessió Login',
    recuperarLoginFinish: '[auth] Fi Recuperar Sessió Login',
    renovarToken : '[auth] Renovar Token Usuari',


    uiCrearError: '[UI] Crear Error',
    uiCrearOk: '[UI] Crear tot Ok',
    uiEsborrarOk: '[UI] Esborrar Ok',
    uiEsborrarOkError: '[UI] Esborrar Ok Eroor',
    uiEsborrarError: '[UI] Esborrar Error',
    uiIniciarCarrega: '[UI] Iniciar càrrega',
    uiFinalitzarCarrega: '[UI] Finalitzar càrrega',
    uiModalUser: '[UI] Obri Modal Modificar ',
    uiIniciarFormulario:'[UI] Iniciar Formulario Action',
    uiFinalitzarFormulario:'[UI] Finalitzar Formulario Action',
    uiActualizarResultats:'[UI] Actualizar Valor Resultats',
    uiActualizarResultatCat:'[UI] Mostar  Resultats categoria',

    configUsuariRecuperarInfo: '[ConfigUsuari] Iniciar recuperar Informació de usuari al servei',
    configUsuariRecuperarInfoFinalitzada: '[ConfigUsuari] Fi Recuperació Informació de usuari al servei',

    configAplicacioRecuperarHistoric:'[ConfigAplicacio] Recuperar Info historic d evaluacions',

    configFormularioInfo:'[ConfigFormulario] Iniciar Formulario',
    configFormularioCategoria:'[ConfigFormulario] Iniciar recuperar info Formulario Cat',
    configFormularioResultats:'[ConfigFormulario] Resultats categoria ',
    // configFormularioRecuperarConstantesFormulario: '[ConfigFormulario] Iniciar recuperar Informació de las Constantes del Formulario',
    // configFormularioRecuperarIndicadoresFormulario: '[ConfigFormulario] Iniciar recuperar Informació de los Indicadores del Formulario',

    
}

// reeucers store actions