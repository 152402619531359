import React, { useEffect, useState } from 'react'
import { UsuariLoginat } from '../ui/UsuariLoginat'

import {  NavLink, useNavigate } from 'react-router-dom'
import { useDispatch,useSelector } from 'react-redux';
import { recuperarConfigUsuari } from '../../actions/configUsuariAction';
import { useTranslation } from 'react-i18next';
import { CercaTable } from './Tabla/CercaTable';
import { recuperarHistorial } from '../../actions/configAppAction';
import { iniciarFormulario } from '../../actions/configFormularioAction';
import { ModificarScreen } from '../register/ModificarScreen';
import { esborrarErrorAction, esborrarErrorOkAction, esborrarOkAction } from '../../actions/ui';

export const MenuPrincipal = () => {
    const dispatch = useDispatch();
    const navegacio = useNavigate();
    
  const [t, i18n] = useTranslation("generic");
    
    const {auth,configUsuari,configApp,ui } = useSelector(state => state);
    const [jsonData, setJsonData] = useState(null);
  const { msgError,formulari} = useSelector((state) => state.ui);
  const { contingut} = useSelector((state) => state.configUsuari);

    useEffect(() => {
        dispatch(recuperarConfigUsuari(auth.tokenJwt)); 
      }, [auth]);

      useEffect(() => {
        if(formulari){
          navegacio("/evaluacion");
        }else{
          navegacio("/");
        }
        if(ui.msgOk !== null){
          setTimeout(()=>dispatch(esborrarOkAction()),10000); 
        }    
        if(ui.msgError !== null){
            setTimeout(()=>dispatch(esborrarErrorAction()),10000);
        }  
      }, [ui]);
      const handleFormulari = (e) => {
        e.preventDefault();
        dispatch(iniciarFormulario(auth.tokenJwt)); 
      };

      function handleJsonFile(event) {
        const file = event.target.files[0];
        const reader = new FileReader();
        console.log("in");
        reader.onload = (e) => {
          const jsonContent = e.target.result;
          try{
            const parsedJson = JSON.parse(jsonContent);
            setJsonData(parsedJson);
            validateJSON(parsedJson);
          }catch (error) {
            console.log("Error de sintaxis en el JSON2:", error);
          }
          
        };
        reader.readAsText(file);
      }

      function validateJSON(jsonData) {
      
          // Definir las categorías y subcategorías a validar
          const requiredCategories  = ["welcome","welcome2","Tabla"];
          const requiredSubcategories  = {
            'welcome':[
              'title'
            ],
            'welcome2':[
              'title'
            ],
            'Tabla': [
              'txtData',
              'txtResGLobal',
              'txtCat1',
              'txtCat2',
              'txtCat3',
              'txtCat4',
              'txtCat5',
              'txtCat6',
              'txtCat7',
              'txtCat8',
              'txtCat9',
              'txtCat10',
              'txtNuevaEvaluacion'
            ]
          }
      
          // Verificar si todas las categorías están presentes en el JSON
  const missingCategories = requiredCategories.filter(cat => !jsonData.hasOwnProperty(cat));

  if (missingCategories.length > 0) {
    console.log(`Falta(n) la(s) siguiente(s) categoría(s): ${missingCategories.join(', ')}`);

  }

  // Verificar si todas las subcategorías están presentes en el JSON
  for (let category in requiredSubcategories) {
    const missingSubcategories = requiredSubcategories[category].filter(subcat => !jsonData[category].hasOwnProperty(subcat));

    if (missingSubcategories.length > 0) {
      console.log(`Falta(n) la(s) siguiente(s) subcategoría(s) en ${category}: ${missingSubcategories.join(', ')}`);
    }
  }
}

  return (
    <>
    <div className="grid grid-cols-1 md:grid-cols-2 items-end gap-6">
      <div className="max-w-[461px]">
        <p className="uppercase font-medium text-sm tracking-[0.84px] after:bg-green-semilight after:absolute after:w-full after:h-2 relative after:left-0 inline-block after:bottom-0 after:-z-10">Municipio</p>
        {configUsuari.contingut!=null ?<h2 className="font-semibold mt-4 text-4xl leading-snug lg:text-5xl lg:leading-[62px]">{contingut.nombre_Mun}</h2>:null}
      </div>
      <div className="flex md:flex-row flex-col md:items-center gap-2 md:gap-16 justify-end max-w-sm md:ml-auto">
        <div className="flex items-center text-left gap-4">
        {msgError != null  ? ( //això es una condició, si es diferent de null
                  <div className="alert alert-danger">{msgError}</div>
                ) : null}
                  <button
                    onClick={handleFormulari}
                    className="btn-green"
                  >
                    {t("Tabla.txtNuevaEvaluacion")}
                  </button>
                  {/* <input type="file" id="json-input" accept=".json" onChange={handleJsonFile}/> */}
                  
        </div>
      </div>
    </div>
        {configUsuari.contingut!=null?
          <ModificarScreen/>
        :null
        }
        <div className="mt-4">
        {configApp.contingut!=null?
          <CercaTable/>
        :null
        }
        </div>
        
        
        
        
    </>
  )
}
