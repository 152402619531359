import React from 'react'
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addFormularioCat1, addFormularioCat4, addProgramaCat4 } from '../../actions/configFormularioAction';
import { crearErrorAction } from '../../actions/ui';
import { calculoPorcentaje } from '../../helpers/FormulasCat';
import { useForm } from "../../hooks/useForm";
import { useTranslation } from "react-i18next";


export const FormularioCat4 = () => {
  const dispatch = useDispatch(); 
  const [t, i18n] = useTranslation("generic");
  const state = useSelector((state) => state);
  const { msgError } = useSelector((state) => state.ui);
  
  const { auth,configFormulario} = useSelector(state => state);
  const [formValues, handleInputChange,reset] = useForm({
    cat4_1: "",
    cat4_2: "",
    cat4_3: "",
    cat4_4: "",
    cat4_5: "",
    cat4_6: "",   
});

const { cat4_1, cat4_2, cat4_3, cat4_4, cat4_5, cat4_6} = formValues;

  const handleCalcularCat = (e) => {
    
    if(validarFormulas()){
      
      dispatch(addFormularioCat4(auth.tokenJwt,cat4_1, cat4_2, cat4_3, cat4_4, cat4_5, cat4_6,configFormulario.infoFormulario.IdEvaluacion));
      reset()
      

    }else{
      console.log("out");
      //setMsgError("")
    }
  };

  const validarFormulas = () => {
    let esCorrecte = true;
    let msgError ="";
    if(isNaN(cat4_1) || !cat4_1 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 4.1 + t("formulario.msgError2");}
    if(isNaN(cat4_2) || !cat4_2 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 4.2 + t("formulario.msgError2");}
    if(isNaN(cat4_3) || !cat4_3 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 4.3 + t("formulario.msgError2");}
    if(isNaN(cat4_4) || !cat4_4 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 4.4 + t("formulario.msgError2");}
    if(isNaN(cat4_5) || !cat4_5 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 4.5 + t("formulario.msgError2");}
    if(isNaN(cat4_6) || !cat4_6 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 4.6 + t("formulario.msgError2");}

    
    if(!esCorrecte){
      dispatch(crearErrorAction(msgError));
    }
    return esCorrecte;
  }
  const handleCalcularPrograma = (e) => {
    if(validarProgramas()){ 
      dispatch(addProgramaCat4(auth.tokenJwt,cat4_1, cat4_2,configFormulario.infoFormulario.IdEvaluacion));
    }else{
      console.log("out");
    }
  };
  const validarProgramas = () => {
    let esCorrecte = true;
    let msgError ="";
    if(isNaN(cat4_1) || !cat4_1 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 4.1 + t("formulario.msgError2");}
    if(isNaN(cat4_2) || !cat4_2 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 4.2 + t("formulario.msgError2");}
    if(!esCorrecte){
      dispatch(crearErrorAction(msgError));
    }
    return esCorrecte;
  }
  return (
    <>
    <div className='ac-panel'>
        <div className="ac-text !p-9 !pt-3 !text-[15px] !font-normal !leading-7 !text-dark-light">
          {msgError != null  ? ( //això es una condició, si es diferent de null
                        <div className="alert alert-danger">{msgError}</div>
                      ) : null}
                      {configFormulario.infoFormularioCategoria.ResultatCat4==null?
    <>
          <div className='sm:col-span-2'>
            <label htmlFor="cat4_1">{t("Categoria4.preg1")}</label>
            <select id={cat4_1} name="cat4_1" defaultValue="" className='form-inputCat' onChange={handleInputChange}>
              <option value=''>{t("CategoriaDesplegable.seleccionar")}</option>
              <option value="2">{t("CategoriaDesplegable.4.1_2")} </option>
              <option value="1">{t("CategoriaDesplegable.4.1_1")} </option>
              <option value="0">{t("CategoriaDesplegable.4.1_0")} </option>
            </select>
          </div>
          <div className='sm:col-span-2'>
            <label htmlFor="cat4_2">{t("Categoria4.preg2")}</label>
            <input type="text" name="cat4_2" id="cat4_2" className='form-inputCat'  onChange={handleInputChange} required/>
          </div>
          <div className='sm:col-span-2'>
            <label htmlFor="cat4_3">{t("Categoria4.preg3")}</label>
            <select id={cat4_3} name="cat4_3" defaultValue="" className='form-inputCat' onChange={handleInputChange}>
              <option value=''>{t("CategoriaDesplegable.seleccionar")}</option>
              <option value="1">{t("CategoriaDesplegable.4.3_1")} </option>
              <option value="0">{t("CategoriaDesplegable.4.3_0")} </option>
            </select>
          </div>
          <div className='sm:col-span-2'>
            <label htmlFor="cat4_4">{t("Categoria4.preg4")}</label>
            <input type="text" className='form-inputCat' name="cat4_4" id="cat4_4"  onChange={handleInputChange} required/>
          </div>
          <div className='sm:col-span-2'>
            <label htmlFor="cat4_5">{t("Categoria4.preg5")}</label>
            <input type="text" className='form-inputCat' name="cat4_5" id="cat4_5"  onChange={handleInputChange} required/>
          </div>
          <div className='sm:col-span-2'>
            <label htmlFor="cat4_6">{t("Categoria4.preg6")}</label>
            <input type="text" className='form-inputCat' name="cat4_6" id="cat4_6"  onChange={handleInputChange} required/>
          </div>
          <div><button onClick={handleCalcularCat} id='cat1' className="btn-turquesa">{t("formulario.enviar")}</button></div>
          </>
      :<>
      <div className='sm:col-span-2'>
          <label htmlFor="cat4_1">{t("CategoriaPrograma.4_1")}  </label>
          <select className='form-inputCat' id={cat4_1} name="cat4_1" value={cat4_1} onChange={handleInputChange}>
            <option value=''>{t("CategoriaDesplegable.seleccionar")}</option>
            <option value="2">{t("CategoriaDesplegable.si")} </option>
            <option value="1">{t("CategoriaDesplegable.mitat")} </option>
            <option value="0">{t("CategoriaDesplegable.no")}</option>
          </select>        </div>
        <div className='sm:col-span-2'>
          <label htmlFor="cat4_2">{t("CategoriaPrograma.4_2")} </label>
          <select className='form-inputCat' id={cat4_2} name="cat4_2" value={cat4_2} onChange={handleInputChange}>
            <option value=''>{t("CategoriaDesplegable.seleccionar")}</option>
            <option value="2">{t("CategoriaDesplegable.si")} </option>
            <option value="1">{t("CategoriaDesplegable.mitat")} </option>
            <option value="0">{t("CategoriaDesplegable.no")}</option>
          </select>        </div>
    <div><button onClick={handleCalcularPrograma} id='cat1' className="btn-turquesa">{t("formulario.enviar")}</button></div>
    </> 
    }
      </div>
    </div>
    </>
  )
}
