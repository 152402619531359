import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useForm } from "../../hooks/useForm";
import isEmail from "validator/lib/isEmail";
import { esborrarErrorAction, crearErrorAction } from "../../actions/ui.js";
import { uiFinalitzarCarrega } from "../../types/types";
import { HeaderPublic } from "../ui/HeaderPublic";
import { FooterPublic } from "../ui/FooterPublic";
import { useTranslation } from "react-i18next";
import { iniciarPeticioRegisterAsincronaAxios } from "../../actions/auth";

export const RegisterScreen = (props) => {
  const dispatch = useDispatch(); //Per despachar accions
  const state = useSelector((state) => state); // Això retorna l'esta actual de l'aplicació, per poder accedir a tot el contingut emmagatzemat allà
  const { ui } = useSelector((state) => state); // Per desestructuració d¡objectes, podem agafar l'objecte desitjat del nostre state
  const { loading, msgError } = useSelector((state) => state.ui); //Per desestructuració, podem agafar propietats d'objectes directament
  const { auth } = useSelector((state) => state);
  //Farem servir useEffect. Es una eina de REact que permet executar una acció automàticament, afegint una condició de dependencia d'estat o no
  const [passwordShown, setPasswordShown] = useState(false);
  const [t, i18n] = useTranslation("generic");
  const [msgError2, setMsgError2] = useState(false);
  const navegacio = useNavigate();

  const [formValuesMunicipio, handleInputChangeMun] = useForm({
    Nombre_Mun: "",
    CP_Mun: "",
    Habitantes_Mun: "",
    PagWeb_Mun: "",
  });

  const { Nombre_Mun, CP_Mun, Habitantes_Mun, PagWeb_Mun } = formValuesMunicipio;

  const [formValuesContacto, handleInputChangeCon] = useForm({
    Nombre_Resp: "",
    Apellidos_Resp: "",
    Email_Resp: "",
    Pwd_Resp: "",
    Telf_Resp:"",
    Correo_Supervisor:"",
    Nombre_Supervisor:""


  });

  const { Nombre_Resp, Apellidos_Resp, Email_Resp, Pwd_Resp,Telf_Resp, Correo_Supervisor,Nombre_Supervisor} = formValuesContacto;

  useEffect(() => {
    //Mirem si la propietat estaLoginat canvia. Si es així, redireccionem a la part privada
    if (auth.tokenJwt !== undefined) {
      navegacio("/");
      setMsgError2(false);
    }
  }, [auth]);

  const handleRegister= (e) => {
    e.preventDefault();
    if (isFormValid()) {
      setMsgError2(true);
      //Todo Cridar a API de login. SI tot es OK, crearem l'acció de Login amb les dades de l'usuari rebudes del servei
      dispatch(iniciarPeticioRegisterAsincronaAxios(Nombre_Resp, Apellidos_Resp,Email_Resp,Pwd_Resp,Telf_Resp,Correo_Supervisor,Nombre_Supervisor,Nombre_Mun,Habitantes_Mun,PagWeb_Mun,CP_Mun))
    } else {
    }
  };
  const handleRegistreLogin = (e) => {
    e.preventDefault();
    navegacio("/login");
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (isFormValid()) {
        setMsgError2(true);
        //Todo Cridar a API de login. SI tot es OK, crearem l'acció de Login amb les dades de l'usuari rebudes del servei
        
        dispatch(iniciarPeticioRegisterAsincronaAxios(Nombre_Resp, Apellidos_Resp,Email_Resp,Pwd_Resp,Telf_Resp,Correo_Supervisor,Nombre_Supervisor,Nombre_Mun,Habitantes_Mun,PagWeb_Mun,CP_Mun))
      } else {
      }
    }
  };

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const isFormValid = () => {
    let esCorrecte = true;
    if (Email_Resp.trim().length === 0 || Pwd_Resp.trim().lenght === 0) {
      setMsgError2(true);
      dispatch(crearErrorAction("Camps obligatoris"));
      esCorrecte = false;
    } else if (!isEmail(Email_Resp)) {
      setMsgError2(true);
      dispatch(crearErrorAction("Email incorrecto"));
      esCorrecte = false;
    } else {
      setMsgError2(false);
      dispatch(esborrarErrorAction());
    }

    return esCorrecte;
  };

  return (
    <>
      <section className="md:py-[120px] py-20">
        <div className="container">
            <div className="grid grid-cols-1">
              <div className="max-w-[500px] text-center mx-auto">
              {msgError != null && msgError2 ? ( //això es una condició, si es diferent de null
                  <div className="alert alert-danger">{msgError}</div>
                ) : null}

                <h2 className="font-semibold mt-4 text-4xl leading-snug lg:text-5xl lg:leading-[62px]">Darse de alta</h2>
              </div>
            </div>



          <div className="grid grid-cols-1 gap-6 mt-12">
            <div className="max-w-[752px] w-full mx-auto">
            <h4 className="uppercase font-medium text-[22px] after:bg-green-semilight after:absolute after:w-full after:h-2 relative after:left-0 inline-block after:bottom-0 after:-z-10">MUNICIPIO</h4>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 mt-6"> {/* DIV DEL FORMULARI MUNICIPI i CONTACTE +boto registar*/}
                  <> {/* DIV DEL FORMULARI MUNICIPI */}
                    <div>
                      <input
                        type="text"
                        value={Nombre_Mun}
                        className="form-input"
                        name="Nombre_Mun"
                        id="Nombre_Mun"
                        tabIndex="1"
                        placeholder={t("login.txtMunicipi")}
                        onChange={handleInputChangeMun}
                        required
                      />
                    </div>
                    <div>
                      <input
                        type="text"
                        value={CP_Mun}
                        className="form-input"
                        name="CP_Mun"
                        id="CP_Mun"
                        tabIndex="1"
                        placeholder={t("login.txtCp")}
                        onChange={handleInputChangeMun}
                        required
                      />
                    </div>
                    <div>
                      <input
                        type="text"
                        value={Habitantes_Mun}
                        className="form-input"
                        name="Habitantes_Mun"
                        id="Habitantes_Mun"
                        tabIndex="1"
                        placeholder={t("login.txtHabitants")}
                        onChange={handleInputChangeMun}
                        required
                      />
                    </div>
                    <div className="mb-4">
                      <input
                        type="text"
                        value={PagWeb_Mun}
                        className="form-input"
                        name="PagWeb_Mun"
                        id="PagWeb_Mun"
                        tabIndex="1"
                        placeholder={t("login.txtWeb")}
                        onChange={handleInputChangeMun}
                        required
                      />
                    </div>
                  </>
                </div>
                <h4 className="uppercase font-medium text-[22px] after:bg-green-semilight after:absolute after:w-full after:h-2 relative after:left-0 inline-block after:bottom-0 after:-z-10">CONTACTO</h4>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 mt-6"> 
                
                  <> {/* DIV DEL FORMULARI CONTACTE */}


                    <div>
                      <input
                        type="text"
                        value={Nombre_Resp}
                        className="form-input"
                        name="Nombre_Resp"
                        id="Nombre_Resp"
                        tabIndex="1"
                        placeholder={t("login.txtNom")}
                        onChange={handleInputChangeCon}
                        required
                      />
                    </div>
                    <div>
                      <input
                        type="text"
                        value={Apellidos_Resp}
                        className="form-input"
                        name="Apellidos_Resp"
                        id="Apellidos_Resp"
                        tabIndex="1"
                        placeholder={t("login.txtCognom")}
                        onChange={handleInputChangeCon}
                        required
                      />
                    </div>
                    <div>
                      <input
                        type="text"
                        value={Email_Resp}
                        className="form-input"
                        name="Email_Resp"
                        id="Email_Resp"
                        tabIndex="1"
                        placeholder={t("login.txtEmail")}
                        onChange={handleInputChangeCon}
                        required
                      />
                    </div>
                    
                    <div>
                      <div className="input-group-merge">
                        <input
                          type={passwordShown ? "text" : "password"}
                          className="js-toggle-password form-input"
                          name="Pwd_Resp"
                          id="Pwd_Resp"
                          placeholder={t("login.txtClau")}
                          aria-label="8+ characters required"
                          onChange={handleInputChangeCon}
                          required
                          minLength="8"
                          onKeyDown={handleKeyPress}
                          data-hs-toggle-password-options='{
                             "target": "#changePassTarget",
                             "defaultClass": "bi-eye-slash",
                             "showClass": "bi-eye",
                             "classChangeTarget": "#changePassIcon"
                           }'
                          value={Pwd_Resp}
                        />
                        <a
                          id="changePassTarget"
                          className="input-group-append input-group-text"
                          onClick={togglePassword}
                        >
                          <i id="changePassIcon" className="bi-eye"></i>
                        </a>
                      </div>
                    </div>

                    <div>
                      <input
                        type="text"
                        value={Telf_Resp}
                        className="form-input"
                        name="Telf_Resp"
                        id="Telf_Resp"
                        tabIndex="1"
                        placeholder={t("login.txtTelf_Resp")}
                        onChange={handleInputChangeCon}
                        required
                      />
                    </div>
                    <div>
                      <input
                        type="text"
                        value={Correo_Supervisor}
                        className="form-input"
                        name="Correo_Supervisor"
                        id="Correo_Supervisor"
                        tabIndex="1"
                        placeholder={t("login.txtCorreo_Supervisor")}
                        onChange={handleInputChangeCon}
                        required
                      />
                    </div>
                    <div>
                      <input
                        type="text"
                        value={Nombre_Supervisor}
                        className="form-input"
                        name="Nombre_Supervisor"
                        id="Nombre_Supervisor"
                        tabIndex="1"
                        placeholder={t("login.txtNombre_Supervisor")}
                        onChange={handleInputChangeCon}
                        required
                      />
                    </div>


            
                  </>
                  <div className="sm:col-span-2">
                    <button
                      onClick={handleRegister}
                      className="btn-green w-full"
                      disabled={loading}
                    >
                      {t("login.btnRegistrar")}
                    </button>
                  </div>

                  <div className="sm:col-span-2">
                  <button
                    onClick={handleRegistreLogin}
                    className="btn-blau"
                    disabled={loading}
                  >
                    {t("login.btnRegisterLogin")}
                  </button>
                </div>
                </div>
                </div>
                

                
                
            </div>
            <div className="col-md-2 d-md-flex justify-content-center flex-column bg-soft-primary p-8 p-md-5 bg-comb">
              <p> </p>
            </div>
          </div>
      </section>

     
    </>
  );
};
