import { t, use } from 'i18next';
import React, { useEffect, useState } from 'react'
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import { useTranslation } from 'react-i18next';
import "react-circular-progressbar/dist/styles.css";
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import 'moment/locale/es';
import 'moment/locale/ca';
import { addResultatsCategoria } from '../../../actions/configFormularioAction';
import { ResultatsCategoria } from './ResultatsCategoria';

export const Resultats = () => {
  const dispatch = useDispatch(); 
  const [t, i18n] = useTranslation("generic");
  
  const { ui,auth,configApp,configUsuari,configFormulario} = useSelector(state => state);


  
  const [data, setData] = useState({

    cat1: "",
    cat2: "",
    cat3: "",
    cat4: "",
    cat5: "",
    cat6: "",
    cat7: "",
    cat8: "",
    cat9: "",
    catTotal: "",
  });
useEffect(() => {
  if(ui.resultatsID!=null){

    if(configApp.contingut[ui.resultatsID].Resultado_TotalCat1 !=null){
      if(configApp.contingut[ui.resultatsID].Resultado_TotalCat1 % 1 == 0){setData(data=>({...data,cat1:configApp.contingut[ui.resultatsID].Resultado_TotalCat1}));}
      else{setData(data=>({...data,cat1:(configApp.contingut[ui.resultatsID].Resultado_TotalCat1*1).toFixed(2)}));}
    }
    if(configApp.contingut[ui.resultatsID].Resultado_TotalCat2 !=null){
      if(configApp.contingut[ui.resultatsID].Resultado_TotalCat2 % 1 == 0){setData(data=>({...data,cat2:configApp.contingut[ui.resultatsID].Resultado_TotalCat2}));}
      else{setData(data=>({...data,cat2:(configApp.contingut[ui.resultatsID].Resultado_TotalCat2*1).toFixed(2)}));}
    }
    if(configApp.contingut[ui.resultatsID].Resultado_TotalCat3 !=null){
      if(configApp.contingut[ui.resultatsID].Resultado_TotalCat3 % 1 == 0){setData(data=>({...data,cat3:configApp.contingut[ui.resultatsID].Resultado_TotalCat3}));}
      else{setData(data=>({...data,cat3:(configApp.contingut[ui.resultatsID].Resultado_TotalCat3*1).toFixed(2)}));}
    }
    if(configApp.contingut[ui.resultatsID].Resultado_TotalCat4 !=null){
      if(configApp.contingut[ui.resultatsID].Resultado_TotalCat4 % 1 == 0){setData(data=>({...data,cat4:configApp.contingut[ui.resultatsID].Resultado_TotalCat4}));}
      else{setData(data=>({...data,cat4:(configApp.contingut[ui.resultatsID].Resultado_TotalCat4*1).toFixed(2)}));}
    }
    if(configApp.contingut[ui.resultatsID].Resultado_TotalCat5 !=null){
      if(configApp.contingut[ui.resultatsID].Resultado_TotalCat5 % 1 == 0){setData(data=>({...data,cat5:configApp.contingut[ui.resultatsID].Resultado_TotalCat5}));}
      else{setData(data=>({...data,cat5:(configApp.contingut[ui.resultatsID].Resultado_TotalCat5*1).toFixed(2)}));}
    }
    if(configApp.contingut[ui.resultatsID].Resultado_TotalCat6 !=null){
      if(configApp.contingut[ui.resultatsID].Resultado_TotalCat6 % 1 == 0){setData(data=>({...data,cat6:configApp.contingut[ui.resultatsID].Resultado_TotalCat6}));}
      else{setData(data=>({...data,cat6:(configApp.contingut[ui.resultatsID].Resultado_TotalCat6*1).toFixed(2)}));}
    }
    if(configApp.contingut[ui.resultatsID].Resultado_TotalCat7 !=null){
      if(configApp.contingut[ui.resultatsID].Resultado_TotalCat7 % 1 == 0){setData(data=>({...data,cat7:configApp.contingut[ui.resultatsID].Resultado_TotalCat7}));}
      else{setData(data=>({...data,cat7:(configApp.contingut[ui.resultatsID].Resultado_TotalCat7*1).toFixed(2)}));}
    }
    if(configApp.contingut[ui.resultatsID].Resultado_TotalCat8 !=null){
      if(configApp.contingut[ui.resultatsID].Resultado_TotalCat8 % 1 == 0){setData(data=>({...data,cat8:configApp.contingut[ui.resultatsID].Resultado_TotalCat8}));}
      else{setData(data=>({...data,cat8:(configApp.contingut[ui.resultatsID].Resultado_TotalCat8*1).toFixed(2)}));}
    }
    if(configApp.contingut[ui.resultatsID].Resultado_TotalCat9 !=null){
      if(configApp.contingut[ui.resultatsID].Resultado_TotalCat9 % 1 == 0){setData(data=>({...data,cat9:configApp.contingut[ui.resultatsID].Resultado_TotalCat9}));}
      else{setData(data=>({...data,cat9:(configApp.contingut[ui.resultatsID].Resultado_TotalCat9*1).toFixed(2)}));}
    }
    if(configApp.contingut[ui.resultatsID].ResultatGlobal !=null){
      if(configApp.contingut[ui.resultatsID].ResultatGlobal % 1 == 0){setData(data=>({...data,catTotal:configApp.contingut[ui.resultatsID].ResultatGlobal}));}
      else{setData(data=>({...data,catTotal:(configApp.contingut[ui.resultatsID].ResultatGlobal*1).toFixed(2)}));}
    }
  }
}, [ui])



  



  return (
    <>
              
        <div></div>
        {auth.admin ==1 ?<p className="uppercase font-medium text-sm tracking-[0.84px] after:bg-green-semilight after:absolute after:w-full after:h-2 relative after:left-0 inline-block after:bottom-0 after:-z-10">{configApp.contingut[ui.resultatsID].Nombre_Mun}</p>
        :<p className="uppercase font-medium text-sm tracking-[0.84px] after:bg-green-semilight after:absolute after:w-full after:h-2 relative after:left-0 inline-block after:bottom-0 after:-z-10">{configUsuari.contingut.nombre_Mun}</p>
        }
    <h2 className="font-semibold mt-4 text-4xl leading-snug lg:text-5xl lg:leading-[62px]">Resultados { moment(configApp.contingut[ui.resultatsID].DataFin_Evaluacion).format('YYYY-MM-DD')}</h2>
    <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mt-12'>
        <Example label={t("formulario.titleCat1")}id={1}>
            <CircularProgressbar
            value={data.cat1}
            text={`${data.cat1}%`}
            background
            backgroundPadding={6}
            styles={buildStyles({
              backgroundColor: "#6f9b19",
              textColor: "#fff",
              pathColor: "#fff",
              trailColor: "transparent"
            })}/>
        </Example>
        <Example label={t("formulario.titleCat2")}id={2}>
            <CircularProgressbar
            value={data.cat2}
            text={`${data.cat2}%`}
            background
            backgroundPadding={6}
            styles={buildStyles({
              backgroundColor: "#e20078",
              textColor: "#fff",
              pathColor: "#fff",
              trailColor: "transparent"
            })}/>
        </Example>
        <Example label={t("formulario.titleCat3")}id={3}>
            <CircularProgressbar
            value={data.cat3}
            text={`${data.cat3}%`}
            background
            backgroundPadding={6}
            styles={buildStyles({
              backgroundColor: "#002b67",
              textColor: "#fff",
              pathColor: "#fff",
              trailColor: "transparent"
            })}/>
        </Example>
        <Example label={t("formulario.titleCat4")}id={4}>
            <CircularProgressbar
            value={data.cat4}
            text={`${data.cat4}%`}
            background
            backgroundPadding={6}
            styles={buildStyles({
              backgroundColor: "#00afa9",
              textColor: "#fff",
              pathColor: "#fff",
              trailColor: "transparent"
            })}/>
        </Example>
        <Example label={t("formulario.titleCat5")}id={5}>
            <CircularProgressbar
            value={data.cat5}
            text={`${data.cat5}%`}
            background
            backgroundPadding={6}
            styles={buildStyles({
              backgroundColor: "#005f71",
              textColor: "#fff",
              pathColor: "#fff",
              trailColor: "transparent"
            })}/>
        </Example>
        <Example label={t("formulario.titleCat6")}id={6}>
            <CircularProgressbar
            value={data.cat6}
            text={`${data.cat6}%`}
            background
            backgroundPadding={6}
            styles={buildStyles({
              backgroundColor: "#6f9b19",
              textColor: "#fff",
              pathColor: "#fff",
              trailColor: "transparent"
            })}/>
        </Example>
        <Example label={t("formulario.titleCat7")}id={7}>
            <CircularProgressbar
            value={data.cat7}
            text={`${data.cat7}%`}
            background
            backgroundPadding={6}
            styles={buildStyles({
              backgroundColor: "#e20078",
              textColor: "#fff",
              pathColor: "#fff",
              trailColor: "transparent"
            })}/>
        </Example>
        <Example label={t("formulario.titleCat8")} id={8}>
            <CircularProgressbar
            value={data.cat8}
            text={`${data.cat8}%`}
            background
            backgroundPadding={6}
            styles={buildStyles({
              backgroundColor: "#002b67",
              textColor: "#fff",
              pathColor: "#fff",
              trailColor: "transparent"
            })}/>
        </Example>
        <Example  label={t("formulario.titleCat9")} id={9}>
            <CircularProgressbar
            value={data.cat9}
            text={`${data.cat9}%`}
            background
            backgroundPadding={6}
            styles={buildStyles({
              backgroundColor: "#00afa9",
              textColor: "#fff",
              pathColor: "#fff",
              trailColor: "transparent"
            })}/>
        </Example>
        
        
        <Example2 label={t("formulario.titleTotal")}>
            <CircularProgressbar
            value={data.catTotal}
            text={`${data.catTotal}%`}
            background
            backgroundPadding={6}
            styles={buildStyles({
              backgroundColor: "#6b6a6f",
              textColor: "#fff",
              pathColor: "#fff",
              trailColor: "transparent"
            })}/>
        </Example2>
        <Example></Example>
        <div className='bg-white duration-300 group pt-14 p-8 text-center hover:shadow-custom rounded-2xl'>
          <div className='border-4 border-white duration-300 inline-block rounded-full p-1 mt-14'>  
            <a href='assets/images/LOGO-CQC.pdf' target={'_blank'}><img src="assets/images/logo.jpg" className="" alt="Ciudadesquecuidan"></img></a>
          </div>
          <p> <a href='assets/images/LOGO-CQC.pdf' download="LOGO-CQC" target={'_blank'}><u>Descarga tu sello</u></a></p>
        </div>
      </div>
      {ui.resultatCat == true?
          <ResultatsCategoria />
        :null
        }
        
    </>
  )
}

function Example(props) {
  const dispatch = useDispatch();
  const { ui,auth,configApp,configUsuari} = useSelector(state => state);

  const handleResultats = (e) => {
    dispatch(addResultatsCategoria(auth.tokenJwt,configApp.contingut[ui.resultatsID].IdEvaluacion,e)); 
  };
  return (
    <div className='bg-white duration-300 group pt-14 p-8 text-center hover:shadow-custom rounded-2xl'>
    <button onClick={()=>handleResultats(props.id)}>
        <div className='border-4 border-white duration-300 inline-block rounded-full p-1'>{props.children}</div>
        <h5 className="mt-4 font-semibold text-lg leading-7 hover:text-green duration-300">{props.label}</h5>
        <p>{props.description}</p>
    </button>
    </div>
  );
}
function Example2(props) {

  return (
    <div className='bg-white duration-300 group pt-14 p-8 text-center hover:shadow-custom rounded-2xl'>
        <div className='border-4 border-white duration-300 inline-block rounded-full p-1'>{props.children}</div>
        <h5 className="mt-4 font-semibold text-lg leading-7 hover:text-green duration-300">{props.label}</h5>
        <p>{props.description}</p>
    </div>
  );
}
