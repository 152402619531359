import React from 'react'

export const FooterPublic = () => {
    return (
        <>
<footer className="bg-white" >
<div className="container py-8">
    <div className="text-center mt-6">
    <img src="assets/images/Logotipo_Memorafundacion_memora_cas_sin_fondo.png" className="rounded-full" alt="Mémora" width="200"/>
    </div>
  </div>
  <div className='bg-dark'>
  <div className="container">
    <div className="grid grid-cols-1">
                    <div className="py-8">
                        <p className="text-[#7B7B7B] text-[15px] leading-7">© 2023 Copyright Ciudadesquecuidan</p>
                    </div>
                </div>

    
  </div>
  </div>
</footer>

        </>
    )
}
