import React, {  useState } from 'react'
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";

import { useTranslation } from "react-i18next";

import moment from 'moment';
import 'moment/locale/es';
import 'moment/locale/ca';
import { uiActualizarResultatsAction } from '../../../actions/ui';
import { useNavigate } from 'react-router-dom';



export const CercaTableADMIN = (props) => {
    const dispatch = useDispatch();
    const navegacio = useNavigate();
    const [t, i18n] = useTranslation("generic");
    const { auth,configApp } = useSelector(state => state);
    let data= [];

    if(configApp.contingut != null && configApp.contingut.length !=0){
            for(let i = 0; i<configApp.contingut.length; i++){
                data.push({
                    IdEvaluacion: configApp.contingut[i].IdEvaluacion,
                    Nombre_Mun: configApp.contingut[i].Nombre_Mun,
                    DataInicio_Evaluacion: moment(configApp.contingut[i].DataInicio_Evaluacion).format('YYYY-MM-DD'),
                    fecha: moment(configApp.contingut[i].DataFin_Evaluacion).format('YYYY-MM-DD'),
                    ResultatGlobal: configApp.contingut[i].ResultatGlobal % 1 == 0 ?configApp.contingut[i].ResultatGlobal: (configApp.contingut[i].ResultatGlobal*1).toFixed(2),
                    Resultado_TotalCat1: configApp.contingut[i].Resultado_TotalCat1 % 1 == 0 ?configApp.contingut[i].Resultado_TotalCat1: (configApp.contingut[i].Resultado_TotalCat1*1).toFixed(2),
                    Resultado_TotalCat2: configApp.contingut[i].Resultado_TotalCat2 % 1 == 0 ?configApp.contingut[i].Resultado_TotalCat2: (configApp.contingut[i].Resultado_TotalCat2*1).toFixed(2),
                    Resultado_TotalCat3: configApp.contingut[i].Resultado_TotalCat3 % 1 == 0 ?configApp.contingut[i].Resultado_TotalCat3: (configApp.contingut[i].Resultado_TotalCat3*1).toFixed(2),
                    Resultado_TotalCat4: configApp.contingut[i].Resultado_TotalCat4 % 1 == 0 ?configApp.contingut[i].Resultado_TotalCat4: (configApp.contingut[i].Resultado_TotalCat4*1).toFixed(2),
                    Resultado_TotalCat5: configApp.contingut[i].Resultado_TotalCat5 % 1 == 0 ?configApp.contingut[i].Resultado_TotalCat5: (configApp.contingut[i].Resultado_TotalCat5*1).toFixed(2),
                    Resultado_TotalCat6: configApp.contingut[i].Resultado_TotalCat6 % 1 == 0 ?configApp.contingut[i].Resultado_TotalCat6: (configApp.contingut[i].Resultado_TotalCat6*1).toFixed(2),
                    Resultado_TotalCat7: configApp.contingut[i].Resultado_TotalCat7 % 1 == 0 ?configApp.contingut[i].Resultado_TotalCat7: (configApp.contingut[i].Resultado_TotalCat7*1).toFixed(2),
                    Resultado_TotalCat8: configApp.contingut[i].Resultado_TotalCat8 % 1 == 0 ?configApp.contingut[i].Resultado_TotalCat8: (configApp.contingut[i].Resultado_TotalCat8*1).toFixed(2),
                    Resultado_TotalCat9: configApp.contingut[i].Resultado_TotalCat9 % 1 == 0 ?configApp.contingut[i].Resultado_TotalCat9: (configApp.contingut[i].Resultado_TotalCat9*1).toFixed(2),
                    index:i
                })
            }
    }
    
    const columns = [
        {
            name: "Nombre",
            selector: row => row.Nombre_Mun,
            sortable: true,
            wrap: true,
            cellExport:row => row.Nombre_Mun,
        },
        {
            name: t("Tabla.txtData"),
            selector: row => row.fecha,
            sortable: true,
            wrap: true,
            cellExport:row => row.fecha,
        },
        {
            name: t("Tabla.txtResGLobal"),
            selector: row => row.ResultatGlobal,
            cellExport: row => row.ResultatGlobal,
            sortable: true,
        },
        {
            name: t("Tabla.txtCat1"),
            selector: row => row.Resultado_TotalCat1,
            cellExport: row => row.Resultado_TotalCat1,
            sortable: true,
        },
        {
            name: t("Tabla.txtCat2"),
            selector: row => row.Resultado_TotalCat2,
            cellExport: row => row.Resultado_TotalCat2,
            sortable: true,
        },
        {
            name: t("Tabla.txtCat3"),
            selector: row => row.Resultado_TotalCat3,
            cellExport: row => row.Resultado_TotalCat3,
            sortable: true,
        },
        {
            name: t("Tabla.txtCat4"),
            selector: row => row.Resultado_TotalCat4,
            cellExport: row => row.Resultado_TotalCat4,
            sortable: true,
        },
        {
            name: t("Tabla.txtCat5"),
            selector: row => row.Resultado_TotalCat5,
            cellExport: row => row.Resultado_TotalCat5,
            sortable: true,
        },
        {
            name: t("Tabla.txtCat6"),
            selector: row => row.Resultado_TotalCat6,
            cellExport: row => row.Resultado_TotalCat6,
            sortable: true,
        },
        ,
        {
            name: t("Tabla.txtCat7"),
            selector: row => row.Resultado_TotalCat7,
            cellExport: row => row.Resultado_TotalCat7,
            sortable: true,
        },
        ,
        {
            name: t("Tabla.txtCat8"),
            selector: row => row.Resultado_TotalCat8,
            cellExport: row => row.Resultado_TotalCat8,
            sortable: true,
        },
        ,
        {
            name: t("Tabla.txtCat9"),
            selector: row => row.Resultado_TotalCat9,
            cellExport: row => row.Resultado_TotalCat9,
            sortable: true,
        }
    ];
    const conditionalRowStyles = [
        {
            when: row => row.index %2 == 0,
            style: {
                backgroundColor: 'rgba(0, 0, 0, 0.1)',
               
                color: '#000000',
                '&:hover': {
                    cursor: 'pointer',
                },
            },
        },
    ];

    const tableData = {
          columns,
          data
    };

    const notebookEntryHandler = (e) => {
        navegacio('/resultats');
        dispatch(uiActualizarResultatsAction(e.index))
    }

    return (
        <>
            
            <DataTableExtensions {...tableData}> 
            <DataTable 
                title="Evaluación Formulario"
                columns={columns}
                data={data}
                conditionalRowStyles={conditionalRowStyles}
                onRowClicked={notebookEntryHandler}
                noHeader
                defaultSortField="fecha"
                defaultSortAsc={false}
                pagination
                highlightOnHover
            />
             </DataTableExtensions> 
            
            
        </>
    )
}
