import React from 'react'

import { LogoCapcaleraGlobal } from './LogoCapcaleraGlobal'
import { UsuariLoginat } from './UsuariLoginat'

export const HeaderPrivat = () => {

    return (
      
      <header className="main-menu bg-white">
        <nav className="top-0 w-full z-50 mobile-menu-bg">
            <div className="container">
                <div className="lg:flex items-center justify-between gap-6">
                    <LogoCapcaleraGlobal />  
                
                    <div id="navbarNav" className="mobile-menu hidden lg:block">
                    <UsuariLoginat />
                    </div>
                </div>
            </div>
        </nav>
      </header>
        
    )
}
