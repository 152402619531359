import React from 'react'
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addFormularioCat1, addFormularioCat3, addProgramaCat3 } from '../../actions/configFormularioAction';
import { crearErrorAction } from '../../actions/ui';
import { calculoPorcentaje } from '../../helpers/FormulasCat';
import { useForm } from "../../hooks/useForm";
import { useTranslation } from "react-i18next";

export const FormularioCat3 = () => {
  const dispatch = useDispatch(); 
  const [t, i18n] = useTranslation("generic");
  const state = useSelector((state) => state);
  const { msgError } = useSelector((state) => state.ui);
  
  const { auth,configFormulario} = useSelector(state => state);
  const [formValues, handleInputChange,reset] = useForm({
    cat3_1: "",
    cat3_2: "",
    cat3_3: "",
    cat3_4: "",
    cat3_5: "",
    cat3_6: "",
    cat3_7: "",
    cat3_8: "",
    cat3_9: "",
    
});

const { cat3_1, cat3_2, cat3_3, cat3_4, cat3_5, cat3_6,cat3_7,cat3_8,cat3_9} = formValues;

  const handleCalcularCat = (e) => {
    
    if(validarFormulas()){
      
      dispatch(addFormularioCat3(auth.tokenJwt,cat3_1, cat3_2, cat3_3, cat3_4, cat3_5, cat3_6,cat3_7,cat3_8,cat3_9,configFormulario.infoFormulario.IdEvaluacion));
      reset()
      

    }else{
      console.log("out");
      //setMsgError("")
    }
  };

  const validarFormulas = () => {
    let esCorrecte = true;
    let msgError ="";
    if(isNaN(cat3_1) || !cat3_1 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 3.1 + t("formulario.msgError2");}
    if(isNaN(cat3_2) || !cat3_2 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 3.2 + t("formulario.msgError2");}
    if(isNaN(cat3_3) || !cat3_3 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 3.3 + t("formulario.msgError2");}
    if(isNaN(cat3_4) || !cat3_4 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 3.4 + t("formulario.msgError2");}
    if(isNaN(cat3_5) || !cat3_5 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 3.5 + t("formulario.msgError2");}
    if(isNaN(cat3_6) || !cat3_6 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 3.6 + t("formulario.msgError2");}
    if(isNaN(cat3_7) || !cat3_7 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 3.7 + t("formulario.msgError2");}
    if(isNaN(cat3_8) || !cat3_8 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 3.8 + t("formulario.msgError2");}
    if(isNaN(cat3_9) || !cat3_9 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 3.9 + t("formulario.msgError2");}

    
    if(!esCorrecte){
      dispatch(crearErrorAction(msgError));
    }
    return esCorrecte;
  }
  const handleCalcularPrograma = (e) => {
    if(validarProgramas()){ 
      dispatch(addProgramaCat3(auth.tokenJwt,cat3_1, cat3_2,cat3_3,cat3_4,configFormulario.infoFormulario.IdEvaluacion));
    }else{
      console.log("out");
    }
  };
  const validarProgramas = () => {
    let esCorrecte = true;
    let msgError ="";
    if(isNaN(cat3_1) || !cat3_1 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 3.1 + t("formulario.msgError2");}
    if(isNaN(cat3_2) || !cat3_2 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 3.2 + t("formulario.msgError2");}
    if(isNaN(cat3_3) || !cat3_3 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 3.3 + t("formulario.msgError2");}
    if(isNaN(cat3_4) || !cat3_4 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 3.4 + t("formulario.msgError2");}
    if(!esCorrecte){
      dispatch(crearErrorAction(msgError));
    }
    return esCorrecte;
  }
  return (
    <>
    <div className='ac-panel'>
     <div className="ac-text !p-9 !pt-3 !text-[15px] !font-normal !leading-7 !text-dark-light">
    {msgError != null  ? ( //això es una condició, si es diferent de null
                  <div className="alert alert-danger" >{msgError}</div>
                ) : null}
                {configFormulario.infoFormularioCategoria.ResultatCat3==null?
    <>
      <div className='sm:col-span-2'>
        <label htmlFor="cat3_1">{t("Categoria3.preg1")} </label>
        <input type="text" className='form-inputCat'  name="cat3_1" id="cat3_1"  onChange={handleInputChange} required/>
        </div>
      <div className='sm:col-span-2'>
        <label htmlFor="cat3_2">{t("Categoria3.preg2")}</label>
        <input type="text" className='form-inputCat'  name="cat3_2" id="cat3_2"  onChange={handleInputChange} required/>
        </div>
      <div className='sm:col-span-2'>
        <label htmlFor="cat3_3">{t("Categoria3.preg3")}</label>
        <input type="text" className='form-inputCat'  name="cat3_3" id="cat3_3"  onChange={handleInputChange} required/>
        </div>
      <div className='sm:col-span-2'>
        <label htmlFor="cat3_4">{t("Categoria3.preg4")} </label>
        <input type="text" className='form-inputCat'  name="cat3_4" id="cat3_4"  onChange={handleInputChange} required/>
        </div>
      <div className='sm:col-span-2'>
        <label htmlFor="cat3_5">{t("Categoria3.preg5")}</label>
        <input type="text" className='form-inputCat'  name="cat3_5" id="cat3_5"  onChange={handleInputChange} required/>
        </div>
      <div className='sm:col-span-2'>
        <label htmlFor="cat3_6">{t("Categoria3.preg6")}</label>
        <input type="text" className='form-inputCat'  name="cat3_6" id="cat3_6"  onChange={handleInputChange} required/>
        </div>
      <div className='sm:col-span-2'>
        <label htmlFor="cat3_7">{t("Categoria3.preg7")}</label>
        <input type="text" className='form-inputCat'  name="cat3_7" id="cat3_7"  onChange={handleInputChange} required/>
        </div>
      <div className='sm:col-span-2'>
        <label htmlFor="cat3_8">{t("Categoria3.preg8")}</label>
        <input type="text" className='form-inputCat'  name="cat3_8" id="cat3_8"  onChange={handleInputChange} required/>
        </div>
      <div className='sm:col-span-2'>
        <label htmlFor="cat3_9">{t("Categoria3.preg9")}</label>
        <input type="text" className='form-inputCat'  name="cat3_9" id="cat3_9"  onChange={handleInputChange} required/>
      </div>

      <div><button onClick={handleCalcularCat} id='cat1' className="btn-turquesa">{t("formulario.enviar")}</button></div>
      </>
      :<>
        <div className='sm:col-span-2'>
          <label htmlFor="cat3_1">{t("CategoriaPrograma.3_1")} </label>
          <select className='form-inputCat' id={cat3_1} name="cat3_1" value={cat3_1} onChange={handleInputChange}>
            <option value=''>{t("CategoriaDesplegable.seleccionar")}</option>
            <option value="2">{t("CategoriaDesplegable.si")} </option>
            <option value="1">{t("CategoriaDesplegable.mitat")} </option>
            <option value="0">{t("CategoriaDesplegable.no")}</option>
          </select>        </div>
        <div className='sm:col-span-2'>
          <label htmlFor="cat3_2">{t("CategoriaPrograma.3_2")} </label>
          <select className='form-inputCat' id={cat3_2} name="cat3_2"  value={cat3_2} onChange={handleInputChange}>
            <option value=''>{t("CategoriaDesplegable.seleccionar")}</option>
            <option value="2">{t("CategoriaDesplegable.si")} </option>
            <option value="1">{t("CategoriaDesplegable.mitat")} </option>
            <option value="0">{t("CategoriaDesplegable.no")}</option>
          </select>        </div>
        <div className='sm:col-span-2'>
          <label htmlFor="cat3_3">{t("CategoriaPrograma.3_3")}  </label>
          <select className='form-inputCat' id={cat3_3} name="cat3_3"  value={cat3_3} onChange={handleInputChange}>
            <option value=''>{t("CategoriaDesplegable.seleccionar")}</option>
            <option value="2">{t("CategoriaDesplegable.si")} </option>
            <option value="1">{t("CategoriaDesplegable.mitat")} </option>
            <option value="0">{t("CategoriaDesplegable.no")}</option>
          </select>        </div>
        <div className='sm:col-span-2'>
          <label htmlFor="cat3_4">{t("CategoriaPrograma.3_4")}  </label>
          <select className='form-inputCat' id={cat3_4} name="cat3_4"  value={cat3_4} onChange={handleInputChange}>
            <option value=''>{t("CategoriaDesplegable.seleccionar")}</option>
            <option value="2">{t("CategoriaDesplegable.si")} </option>
            <option value="1">{t("CategoriaDesplegable.mitat")} </option>
            <option value="0">{t("CategoriaDesplegable.no")}</option>
          </select>        </div>
      <div><button onClick={handleCalcularPrograma} id='cat1' className="btn-turquesa">{t("formulario.enviar")}</button></div>
      </> 
    }
    </div>
    </div>
    </>
  )
}
