import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import { authReducer } from "../reducers/authReducer";
import thunk from 'redux-thunk'
import { uiReducer } from "../reducers/uiReducer";
import { configUsuariReducer } from "../reducers/configUsuariReducer";
import { configAplicacioReducer } from "../reducers/configAplicacioReducer";
import { configFormularioReducer } from "../reducers/configFormularioReducer";


const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

//Thunk es l'encarregat de gestionar les peticions asyncrones. Normalment les que es fan contra un server, per rebre una resposta d'una petiucio

const reducers = combineReducers({
    auth : authReducer,
    ui : uiReducer,
    configUsuari: configUsuariReducer,
    configApp: configAplicacioReducer,
    configFormulario: configFormularioReducer,

});

export const store = createStore(
    reducers,
    composeEnhancers(applyMiddleware(thunk))
    );