//Aquestes accions, com son sincrones, es poden executar directament

import { types } from "../types/types"

export const crearErrorAction = ( missatgeError) =>{
    return {
        type: types.uiCrearError,
        payload: missatgeError
    }

}

export const crearTotOkAction = ( missatgeOk) =>{
    return {
        type: types.uiCrearOk,
        payload: missatgeOk
    }

}
export const esborrarOkAction = () =>{
    return {
        type: types.uiEsborrarOk
    }

}
export const esborrarErrorAction = () =>{
    return {
        type: types.uiEsborrarError
    }

}

export const uiIniciarCarregaAction = ( ) =>{
    return {
        type: types.uiIniciarCarrega,
        payload: true
    }
}

export const uiFinalitzarCarregaAction = ( ) =>{
    return {
        type: types.uiFinalitzarCarrega,
        payload: false
    }
}

export const uiModalUserAction = ( valor) =>{
    return {
        type: types.uiModalUser,
        payload: valor
    }
}
export const uiIniciarFormularioAction = ( ) =>{
    return {
        type: types.uiIniciarFormulario,
        payload: true
    }
}
export const uiFinalitzarFormularioAction = ( ) =>{
    return {
        type: types.uiIniciarFormulario,
        payload: false
    }
}
export const uiActualizarResultatsAction = ( valor) =>{
    return {
        type: types.uiActualizarResultats,
        payload: valor
    }
}

export const uiActualizarResultatCatAction = ( valor) =>{
    return {
        type: types.uiActualizarResultatCat,
        payload: valor
    }
}

