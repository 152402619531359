import React from 'react'
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addFormularioCat1, addFormularioCat8, addProgramaCat8 } from '../../actions/configFormularioAction';
import { crearErrorAction } from '../../actions/ui';
import { calculoPorcentaje } from '../../helpers/FormulasCat';
import { useForm } from "../../hooks/useForm";
import { useTranslation } from "react-i18next";

export const FormularioCat8 = () => {
  const dispatch = useDispatch(); 
  const state = useSelector((state) => state);
  const { msgError } = useSelector((state) => state.ui);
  const [t, i18n] = useTranslation("generic");
  const { auth,configFormulario} = useSelector(state => state);
  const [formValues, handleInputChange,reset] = useForm({
    cat8_1: "",
    cat8_2: "",
    cat8_3: "",
    cat8_4: "",
    cat8_5: "",
    cat8_6: "",
    cat8_7: "",
    cat8_8: "",
    cat8_9: "",
    
});

const { cat8_1, cat8_2, cat8_3, cat8_4, cat8_5, cat8_6,cat8_7,cat8_8,cat8_9} = formValues;

  const handleCalcularCat = (e) => {
    
    if(validarFormulas()){
      
      dispatch(addFormularioCat8(auth.tokenJwt,cat8_1, cat8_2, cat8_3, cat8_4, cat8_5, cat8_6,cat8_7,cat8_8,cat8_9,configFormulario.infoFormulario.IdEvaluacion));
      reset();
      

    }else{
      console.log("out");
      //setMsgError("")
    }
  };
  const handleCalcularPrograma = (e) => {
    if(validarProgramas()){ 
      dispatch(addProgramaCat8(auth.tokenJwt,cat8_1, cat8_2,configFormulario.infoFormulario.IdEvaluacion));
    }else{
    }
  };
  const validarProgramas = () => {
    let esCorrecte = true;
    let msgError ="";
    if(isNaN(cat8_1) || !cat8_1 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 8.1 + t("formulario.msgError2");}
    if(isNaN(cat8_2) || !cat8_2 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 8.2 + t("formulario.msgError2");} 
    if(!esCorrecte){
      dispatch(crearErrorAction(msgError));
    }
    return esCorrecte;
  }

  const validarFormulas = () => {
    let esCorrecte = true;
    let msgError ="";
    if(isNaN(cat8_1) || !cat8_1 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 8.1 + t("formulario.msgError2");}
    if(isNaN(cat8_2) || !cat8_2 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 8.2 + t("formulario.msgError2");}
    if(isNaN(cat8_3) || !cat8_3 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 8.3 + t("formulario.msgError2");} 
    if(isNaN(cat8_4) || !cat8_4 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 8.4 + t("formulario.msgError2");}
    if(isNaN(cat8_5) || !cat8_5 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 8.5 + t("formulario.msgError2");}
    if(isNaN(cat8_6) || !cat8_6 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 8.6 + t("formulario.msgError2");}
    if(isNaN(cat8_7) || !cat8_7 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 8.7 + t("formulario.msgError2");}
    if(isNaN(cat8_8) || !cat8_8 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 8.8 + t("formulario.msgError2");}
    if(isNaN(cat8_9) || !cat8_9 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 8.9 + t("formulario.msgError2");}
    if(cat8_8 == ""){esCorrecte =false;}

    if(!esCorrecte ){
      dispatch(crearErrorAction(msgError));
    }
    return esCorrecte;
  }

  return (
    <>
    <div className='ac-panel'>
      <div className="ac-text !p-9 !pt-3 !text-[15px] !font-normal !leading-7 !text-dark-light">
        {msgError != null  ? ( //això es una condició, si es diferent de null
                      <div className="alert alert-danger">{msgError}</div>
                    ) : null}
                    {configFormulario.infoFormularioCategoria.ResultatCat8==null?
    <>
        <div className='sm:col-span-2'>
        <label htmlFor="cat8_1">{t("Categoria8.preg1")}</label>
        <select className='form-inputCat' id={cat8_1} name="cat8_1" defaultValue="" onChange={handleInputChange}>
            <option value=''>{t("CategoriaDesplegable.seleccionar")}</option>
            <option value="2">{t("CategoriaDesplegable.8.1_2")} </option>
            <option value="1">{t("CategoriaDesplegable.8.1_1")} </option>
            <option value="0">{t("CategoriaDesplegable.8.1_0")}</option>
          </select>
        </div>
        <div className='sm:col-span-2'>
          <label htmlFor="cat8_2">{t("Categoria8.preg2")}</label>
          <input className='form-inputCat' type="text" name="cat8_2" id="cat8_2"  onChange={handleInputChange} required/>
        </div>
        <div className='sm:col-span-2'>
          <label htmlFor="cat8_3">{t("Categoria8.preg3")}</label>
          <input className='form-inputCat' type="text" name="cat8_3" id="cat8_3"  onChange={handleInputChange} required/>
        </div>
        <div className='sm:col-span-2'>
          <label htmlFor="cat8_4">{t("Categoria8.preg4")} </label>
          <input className='form-inputCat' type="text" name="cat8_4" id="cat8_4"  onChange={handleInputChange} required/>
        </div>
        <div className='sm:col-span-2'>
          <label htmlFor="cat8_5">{t("Categoria8.preg5")}</label>
          <input className='form-inputCat' type="text" name="cat8_5" id="cat8_5"  onChange={handleInputChange} required/>
        </div>
        <div className='sm:col-span-2'>
          <label htmlFor="cat8_6">{t("Categoria8.preg6")}</label>
          <select className='form-inputCat' id={cat8_6} name="cat8_6" defaultValue="" onChange={handleInputChange}>
            <option value=''>{t("CategoriaDesplegable.seleccionar")}</option>
            <option value="3">{t("CategoriaDesplegable.8.6_3")} </option>
            <option value="2">{t("CategoriaDesplegable.8.6_2")} </option>
            <option value="1">{t("CategoriaDesplegable.8.6_1")} </option>
            <option value="0">{t("CategoriaDesplegable.8.6_0")}</option>
          </select>
        </div>
        <div className='sm:col-span-2'>
          <label htmlFor="cat8_7">{t("Categoria8.preg7")}</label>
          <select className='form-inputCat' id={cat8_7} name="cat8_7" defaultValue="" onChange={handleInputChange}>
            <option value=''>{t("CategoriaDesplegable.seleccionar")}</option>
            <option value="3">{t("CategoriaDesplegable.8.7_3")} </option>
            <option value="2">{t("CategoriaDesplegable.8.7_2")} </option>
            <option value="1">{t("CategoriaDesplegable.8.7_1")} </option>
            <option value="0">{t("CategoriaDesplegable.8.7_0")}</option>
          </select>
        </div>

        <div className='sm:col-span-2'>
          <label htmlFor="cat8_4">{t("Categoria8.preg8")} </label>
          <input className='form-inputCat' type="text" name="cat8_8" id="cat8_8"  onChange={handleInputChange} required/>
        </div>

        <div className='sm:col-span-2'>
          <label htmlFor="cat8_9">{t("Categoria8.preg9")} </label>
          <select className='form-inputCat' id={cat8_9} name="cat8_9" defaultValue="" onChange={handleInputChange}>
            <option value=''>{t("CategoriaDesplegable.seleccionar")}</option>
            <option value="3">{t("CategoriaDesplegable.8.9_3")} </option>
            <option value="2">{t("CategoriaDesplegable.8.9_2")} </option>
            <option value="1">{t("CategoriaDesplegable.8.9_1")} </option>
            <option value="0">{t("CategoriaDesplegable.8.9_0")}</option>
          </select>
        </div>


        <div><button onClick={handleCalcularCat} id='cat1' className="btn-turquesa">{t("formulario.enviar")}</button></div>
        </>
      :<>
      <div className='sm:col-span-2'>
          <label htmlFor="cat8_1">{t("CategoriaPrograma.8_1")} </label>
          <select className='form-inputCat' id={cat8_1} name="cat8_1" value={cat8_1} onChange={handleInputChange}>
            <option value=''>{t("CategoriaDesplegable.seleccionar")}</option>
            <option value="2">{t("CategoriaDesplegable.si")} </option>
            <option value="1">{t("CategoriaDesplegable.mitat")} </option>
            <option value="0">{t("CategoriaDesplegable.no")}</option>
          </select>        </div>
        <div className='sm:col-span-2'>
          <label htmlFor="cat8_2">{t("CategoriaPrograma.8_2")} </label>
          <select className='form-inputCat'  id={cat8_2} name="cat8_2" value={cat8_2} onChange={handleInputChange}>
            <option value=''>{t("CategoriaDesplegable.seleccionar")}</option>
            <option value="2">{t("CategoriaDesplegable.si")} </option>
            <option value="1">{t("CategoriaDesplegable.mitat")} </option>
            <option value="0">{t("CategoriaDesplegable.no")}</option>
          </select>        </div>
    <div><button onClick={handleCalcularPrograma} id='cat1' className="btn-turquesa">{t("formulario.enviar")}</button></div>
    </> 
    }
      </div>
    </div>
    </>
  )
}
