import { t, use } from 'i18next';
import React, { useEffect, useState } from 'react'
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import { useTranslation } from 'react-i18next';
import "react-circular-progressbar/dist/styles.css";
import { useDispatch, useSelector } from 'react-redux';
import { configAplicacioRecuperarHistoric } from '../../../actions/configFormularioAction';

import Modal from 'react-modal';

import moment from 'moment';
import 'moment/locale/es';
import 'moment/locale/ca';
import { uiActualizarResultatCatAction } from '../../../actions/ui';

export const ResultatsCategoria = () => {
  const dispatch = useDispatch(); 
  const [t, i18n] = useTranslation("generic");
  
  const { ui,auth,configFormulario,configUsuari,configApp} = useSelector(state => state);

  
  const [rows, setRow] = useState([]);
  const [catName, setCatname] = useState(null);

  
useEffect(() => {
  if(ui.resultatCat == true){
    let rows2 = []
    let num="titleCat"+configFormulario.resultatsCategoria.NumCat;

    setCatname(t("formulario."+num));
      for (let index = 1; index <= configFormulario.resultatsCategoria.numResultados; index++) {
          if(configFormulario.resultatsCategoria['resultat'+index] % 1 == 0){
            rows2.push({name:configFormulario.resultatsCategoria.NumCat+"."+index+".",resultado:configFormulario.resultatsCategoria['resultat'+index]*100,
          color:comprovarColor(configFormulario.resultatsCategoria['resultat'+index])});
        }
          else{rows2.push({name:configFormulario.resultatsCategoria.NumCat+"."+index+".",resultado:(configFormulario.resultatsCategoria['resultat'+index]*100).toFixed(2),
          color:comprovarColor(configFormulario.resultatsCategoria['resultat'+index])});
      }
    }
      setRow(rows2);
    }
    
}, [ui])

const comprovarColor=(e)=>{
if(e<0.4){return "vermell";}
else if(e>0.60){return "verd";}else{
  return "groc";
}

}

const closeModal = () => {
  // TODO: cerrar el modal
  dispatch( uiActualizarResultatCatAction(false) )
  
}
const customStyles = {
  position: 'absolute' ,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
  return (
    <>
        <Modal id="modalUser"
          isOpen={ ui.resultatCat }
          style={ customStyles }
          closeTimeoutMS={ 200 }
          className="modal fade bd-example-modal-xl show"
          ariaHideApp={false}
          backdrop="static"
          keyboard={false}
          overlayClassName="modal-fondo"
        >  
        <button type="button" onClick={closeModal} className="btn-close align-right" data-bs-dismiss="modal" aria-label="Close">{t("login.txtClose")}</button>  
        <div className='modal-body'>
                <div className="position-relative">
                    <div className="card card-shadow card-login"></div>  
                    <div className='container p-8'>
        {/* <div></div>
        {auth.admin ==1 ?<p className="uppercase font-medium text-sm tracking-[0.84px] after:bg-green-semilight after:absolute after:w-full after:h-2 relative after:left-0 inline-block after:bottom-0 after:-z-10">{configApp.contingut[ui.resultatsID].Nombre_Mun}</p>
        :<p className="uppercase font-medium text-sm tracking-[0.84px] after:bg-green-semilight after:absolute after:w-full after:h-2 relative after:left-0 inline-block after:bottom-0 after:-z-10">{configUsuari.contingut.nombre_Mun}</p>
        }
    <h2 className="font-semibold mt-4 text-4xl leading-snug lg:text-5xl lg:leading-[62px]">Resultados { moment(configApp.contingut[ui.resultatsID].DataInicio_Evaluacion).format('YYYY-MM-DD')}</h2>
    <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mt-12'> */}

      <table  size="small" aria-label="a dense table" className='table ml-20' >
        <thead>
          <tr className='p-8'>
            {catName!=null?
            <th className='p-8'>{catName}</th>
            :null}
            <th align="right">Resultado</th>
          </tr>
        </thead>
        <tbody>
          {rows.map((row) => (
            <tr key={row.name} >
              <td component="td" scope="row" align="left" className=' p-4'>
                {row.name}
              </td>
              <td id={row.color} align="center" className=' p-4'>{row.resultado}</td>

            </tr>
          ))}
        </tbody>
      </table>
    {/* </div> */}
    </div>
    </div>
    </div>
            
    </Modal> 
    </>
  )
}


