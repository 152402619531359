import { types } from "../types/types"
import { crearErrorAction, uiIniciarCarregaAction, uiFinalitzarCarregaAction,uiFinalitzarFormularioAction, uiActualizarResultatsAction, uiModalUserAction } from "./ui";
import axios from 'axios';
import { ConfigUsuariRecuperarInfoFinalitzat } from "./configUsuariAction";
import { configAplicacioRecuperarHistoric } from "./configAppAction";
import { ConfigFormulario,ConfigFormularioCategoria } from "./configFormularioAction";



const peticioApi = axios.create({
    headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8','Accept':'application/json' },
    withCredentials: true,
    baseURL: process.env.REACT_APP_DOMINI_API_CIUDAD
 })


 export const iniciarPeticioLoginAsincronaAxios = (email, clau) =>{
    //Aquesta funció (Middleware), retorna un callback. Just quan finalitza la petició, execitarà l'acció 
    return (dispatch) =>{
        dispatch(uiIniciarCarregaAction());      
        var dadesFormulari = {
            'usuari': email,
            'clau': clau
        };
        var formBody = [];
            for (var property in dadesFormulari) {
            var encodedKey = encodeURIComponent(property);
            var encodedValue = encodeURIComponent(dadesFormulari[property]);
            formBody.push(encodedKey + "=" + encodedValue);
            }
            formBody = formBody.join("&");
        
        peticioApi.post(`/auth/login`, formBody )
        .then(response => {
            let data = response.data;
           
            if (response.status != 200) {
                // get error message from body or default to response status
                //const error = (data && data.message) || response.status;
                dispatch(crearErrorAction("Error del servei d'autenticació: " +  JSON.stringify(data)));
            }else
            {
                //Fem el dispatch de l'acció login amb les dades rebudes del servei
                if(data.ok)
                {
                    let { token } = data;
                    let { id,nombre,apellido,email,telf,admin ,email_supervisor,nom_supervisor,IdMunicipio} = data.contingut;
                    
                    dispatch(login(token, id,nombre,apellido,admin,IdMunicipio));
                    
                }else
                {
                    //El servei d'aunteticació reporta un error de login
                    dispatch(crearErrorAction(data.msg));
                }  
            }

            dispatch(uiFinalitzarCarregaAction());
            //element.innerHTML = data.id;
        })
        .catch(error => {
           // element.parentElement.innerHTML = `Error: ${error}`;
            dispatch(crearErrorAction("Excepció no controlada -  " + JSON.stringify(error)));
            console.error('There was an error!', error);
            dispatch(uiFinalitzarCarregaAction());
        });
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        
       
        /*setTimeout(() => {
                //Als 3 segons, farem un dispatch del l'acció Login. 
                
                dispatch(login(123,345,"German, usuari autenticat"));

            }, 3500);*/
    }


}
export const  recuperarLoginActionAxios =   () => {
    //Aquesta funció (Middleware), retorna un callback. Just quan finalitza la petició, execitarà l'acció 
    return async dispatch =>{
        
        dispatch(uiIniciarCarregaAction());      
               
            peticioApi.get(`/auth/esticLoginat` )
            .then(response => {
                
             
              let data = response.data;
              //new Promise((resolve, reject) => setTimeout(() => resolve(), 1500));
              
              // check for error response
              if (response.status != 200) {
                  // get error message from body or default to response status
                  //const error = (data && data.message) || response.status;
                  dispatch(crearErrorAction("Error del servei d'autenticació: " +  JSON.stringify(data)));
                  return Promise.resolve(false);
                  
              }else
              {
                  //Fem el dispatch de l'acció login amb les dades rebudes del servei
                  if(data.ok)
                  {
                      let { token } = data;

                      let { id,nombre,apellido,admin,IdMunicipio} = data.contingut;
                      dispatch(recuperarLogin(token, id,nombre,apellido,admin,IdMunicipio));
                      dispatch(uiFinalitzarCarregaAction());

                      return Promise.resolve(true);
                  }else
                  {
                      //El servei d'aunteticació reporta un error de login
                      dispatch(crearErrorAction(data.msg));
                      dispatch(uiFinalitzarCarregaAction());

                      return Promise.resolve(false);
                  }  
              }

              dispatch(uiFinalitzarCarregaAction());
              //element.innerHTML = data.id;
          })
          .catch(error => {
             // element.parentElement.innerHTML = `Error: ${error}`;
              dispatch(crearErrorAction("Excepció no controlada -  " + JSON.stringify(error)));
              console.error('There was an error!', error);
              dispatch(uiFinalitzarCarregaAction());
              return Promise.reject()
          });
            //TODO - Crear un fitxer on guardem constants segons l'entorn 
       
      
        /*setTimeout(() => {
                //Als 3 segons, farem un dispatch del l'acció Login. 
                
                dispatch(login(123,345,"German, usuari autenticat"));

            }, 3500);*/
    }


}
export const iniciarPeticioRegisterAsincronaAxios = (Nombre_Resp, Apellidos_Resp,Email_Resp,Pwd_Resp,Telf_Resp,Correo_Supervisor,Nombre_Supervisor,Nombre_Mun,Habitantes_Mun,PagWeb_Mun,CP_Mun) =>{
    //Aquesta funció (Middleware), retorna un callback. Just quan finalitza la petició, execitarà l'acció 
    return (dispatch) =>{
        dispatch(uiIniciarCarregaAction());      
        var dadesFormulari = {
            'Nombre_Resp': Nombre_Resp,
            'Apellidos_Resp': Apellidos_Resp,
            'Email_Resp': Email_Resp,
            'Pwd_Resp': Pwd_Resp,
            'Telf_Resp': Telf_Resp,
            'Correo_Supervisor': Correo_Supervisor,
            'Nombre_Supervisor': Nombre_Supervisor,
            'Nombre_Mun': Nombre_Mun,
            'Habitantes_Mun': Habitantes_Mun,
            'PagWeb_Mun': PagWeb_Mun,
            'CP_Mun': CP_Mun
        };
        console.log(dadesFormulari);
        var formBody = [];
            for (var property in dadesFormulari) {
            var encodedKey = encodeURIComponent(property);
            var encodedValue = encodeURIComponent(dadesFormulari[property]);
            formBody.push(encodedKey + "=" + encodedValue);
            }
            formBody = formBody.join("&");
        
        peticioApi.post(`/add/registro`, formBody )
        .then(response => {
            let data = response.data;
           
            if (response.status != 200) {
                // get error message from body or default to response status
                //const error = (data && data.message) || response.status;
                dispatch(crearErrorAction("Error del servei d'autenticació: " +  JSON.stringify(data)));
            }else
            {
                //Fem el dispatch de l'acció login amb les dades rebudes del servei
                if(data.ok)
                {
                    let { token } = data;
                    let { id,nombre,apellido,admin,IdMunicipio} = data.contingut;
                    
                    dispatch(login(token, id,nombre,apellido,admin,IdMunicipio));
                    
                }else
                {
                    //El servei d'aunteticació reporta un error de login
                    dispatch(crearErrorAction(data.msg));
                }  
            }

            dispatch(uiFinalitzarCarregaAction());
            //element.innerHTML = data.id;
        })
        .catch(error => {
           // element.parentElement.innerHTML = `Error: ${error}`;
            dispatch(crearErrorAction("Excepció no controlada -  " + JSON.stringify(error)));
            console.error('There was an error!', error);
            dispatch(uiFinalitzarCarregaAction());
        });
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        
       
        /*setTimeout(() => {
                //Als 3 segons, farem un dispatch del l'acció Login. 
                
                dispatch(login(123,345,"German, usuari autenticat"));

            }, 3500);*/
    }


}
export const iniciarPeticioModificarAsincronaAxios = (token,Nombre_Resp, Apellidos_Resp,Email_Resp,Telf_Resp,Correo_Supervisor,Nombre_Supervisor,Nombre_Mun,Habitantes_Mun,PagWeb_Mun,CP_Mun) =>{
    //Aquesta funció (Middleware), retorna un callback. Just quan finalitza la petició, execitarà l'acció 
    return (dispatch) =>{
        dispatch(uiIniciarCarregaAction());      
        var dadesFormulari = {
            'Nombre_Resp': Nombre_Resp,
            'Apellidos_Resp': Apellidos_Resp,
            'Email_Resp': Email_Resp,
            'Telf_Resp': Telf_Resp,
            'Correo_Supervisor': Correo_Supervisor,
            'Nombre_Supervisor': Nombre_Supervisor,
            'Nombre_Mun': Nombre_Mun,
            'Habitantes_Mun': Habitantes_Mun,
            'PagWeb_Mun': PagWeb_Mun,
            'CP_Mun': CP_Mun
        };
        var formBody = [];
            for (var property in dadesFormulari) {
            var encodedKey = encodeURIComponent(property);
            var encodedValue = encodeURIComponent(dadesFormulari[property]);
            formBody.push(encodedKey + "=" + encodedValue);
            }
            formBody = formBody.join("&");
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8','Accept':'application/json','Authorization' : token },
            credentials: 'include',
            body: formBody,
            mode: 'cors'
        };


        fetch( process.env.REACT_APP_DOMINI_API_CIUDAD + '/mod/modificar',requestOptions)
            .then(async response => {               
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();
 
                if (!response.ok) {
                dispatch(crearErrorAction("Error del servei d'autenticació: " +  JSON.stringify(data)));
                }else
                {
                    if(data.ok)
                    {
                    let { token } = data;
                    let { id,nombre,apellido,admin,IdMunicipio} = data.contingut;
                    
                    dispatch(login(token, id,nombre,apellido,admin,IdMunicipio));
                    dispatch(ConfigUsuariRecuperarInfoFinalitzat(true,data.contingut));
                    
                }else
                {
                    //El servei d'aunteticació reporta un error de login
                    dispatch(crearErrorAction(data.msg));
                }  
            }

            dispatch(uiFinalitzarCarregaAction());
            //element.innerHTML = data.id;
        })
        .catch(error => {
           // element.parentElement.innerHTML = `Error: ${error}`;
            dispatch(crearErrorAction("Excepció no controlada -  " + JSON.stringify(error)));
            console.error('There was an error!', error);
            dispatch(uiFinalitzarCarregaAction());
        });
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        
       
        /*setTimeout(() => {
                //Als 3 segons, farem un dispatch del l'acció Login. 
                
                dispatch(login(123,345,"German, usuari autenticat"));

            }, 3500);*/
    }


}
export const renovarTokenAction = (token) =>{
    //Aquesta funció (Middleware), retorna un callback. Just quan finalitza la petició, execitarà l'acció 
    return (dispatch) =>{
        
        //dispatch(uiIniciarCarregaAction());      

            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8','Accept':'application/json','Authorization' : token },
                credentials: 'include',
                mode: 'cors'
            };

            //TODO - Crear un fitxer on guardem constants segons l'entorn 
        fetch(process.env.REACT_APP_DOMINI_API_CIUDAD + '/auth/renewtoken',requestOptions)
            .then(async response => {
          
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();
              
        
                // check for error response
                if (!response.ok) {
                    // get error message from body or default to response status
                    //const error = (data && data.message) || response.status;
                    dispatch(crearErrorAction("Error del servei d'autenticació: " +  JSON.stringify(data)));
                }else
                {
                    //Fem el dispatch de l'acció login amb les dades rebudes del servei
                    if(data.ok)
                    {

                        let { token } = data;
                        let { id,nombre,apellido,admin,IdMunicipio} = data.contingut;
                        dispatch(renovarToken(token, id,nombre,apellido,admin,IdMunicipio));
 
                    }else
                    {
                        dispatch(iniciDesloginar());
                        //El servei d'aunteticació reporta un error de login
                        //dispatch(crearErrorAction(data.msg));
                    }  
                }

                //dispatch(uiFinalitzarCarregaAction());
                //element.innerHTML = data.id;
            })
            .catch(error => {
               // element.parentElement.innerHTML = `Error: ${error}`;
                dispatch(crearErrorAction("Excepció no controlada -  " + JSON.stringify(error)));
                console.error('There was an error!', error);
                //dispatch(uiFinalitzarCarregaAction());
            });
      
        /*setTimeout(() => {
                //Als 3 segons, farem un dispatch del l'acció Login. 
                
                dispatch(login(123,345,"German, usuari autenticat"));

            }, 3500);*/
    }


}




export const desloginarAction =  () => {
    
    return  (dispatch) =>{
        
           
               
         peticioApi.get(`/auth/desloginar` )
            .then(response => {
                
   
              let data = response.data;
        
              // check for error response
              if (response.status != 200) {
                  // get error message from body or default to response status
                  //const error = (data && data.message) || response.status;
                  dispatch(crearErrorAction("Error del servei desloginar: " +  JSON.stringify(data)));
              }else
              {
                
                  dispatch(ConfigUsuariRecuperarInfoFinalitzat());
                  dispatch(configAplicacioRecuperarHistoric());
                  dispatch(ConfigFormulario())
                  dispatch(ConfigFormularioCategoria())
                  dispatch(uiFinalitzarFormularioAction())
                  dispatch(uiActualizarResultatsAction(null))
                  dispatch(uiModalUserAction(false))
              }

              dispatch(uiFinalitzarCarregaAction());
              //element.innerHTML = data.id;
          })
          .catch(error => {
             // element.parentElement.innerHTML = `Error: ${error}`;
              dispatch(crearErrorAction("Excepció no controlada -  " + JSON.stringify(error)));
              console.error('There was an error!', error);
              dispatch(uiFinalitzarCarregaAction());
          });
            //TODO - Crear un fitxer on guardem constants segons l'entorn 
      
    }


}

export const login = (tokenJwt, id,nombre,apellido,admin,IdMunicipio) =>{
    return {
        type: types.login,
        contingut:
        {
            tokenJwt,
            id,
            nombre,
            apellido,
            admin,
            IdMunicipio
        }
    }

}
export const recuperarLogin = (tokenJwt, id,nombre,apellido,admin,IdMunicipio) =>{
    return {
        type: types.recuperarLogin,
        contingut:
        {
            tokenJwt,
            id,
            nombre,
            apellido,
            admin,
            IdMunicipio
        }
    }
}
export const renovarToken = (tokenJwt, id,nombre,apellido,admin,IdMunicipio) =>{
    return {
        type: types.renovarToken,
        contingut:
        {
            tokenJwt,
            id,
            nombre,
            apellido,
            admin,
            IdMunicipio
        }
    }
}


export const iniciDesloginar = () => {
    return async (dispatch) =>{
        
        dispatch(desloginarAction());
        //Crear l'acció per desloginar l'usuari via API
        dispatch(logout());
    }
}


export const logout = () => {
    return { 
        type:types.logout
    }
}


