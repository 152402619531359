import React from 'react';
import { Switch, Routes,Route, Navigate } from 'react-router-dom';
import { LoginScreen } from '../Component/login/LoginScreen';
import PropTypes from 'prop-types';
import { RegisterScreen } from '../Component/register/RegisterScreen';
import { HeaderPublic } from '../Component/ui/HeaderPublic';
import { FooterPublic } from '../Component/ui/FooterPublic';
// import { LoginAdmin } from '../Component/login/LoginAdmin';


export const PublicRoute = () => {
    
   

    return (
        <>
                <HeaderPublic />
                    <Routes>
                    <Route path="/" element={<LoginScreen />} />
                    <Route path="/register" element={<RegisterScreen />} />
                    {/* <Route exact path="/admin/:tokenAcces" element={<LoginAdmin />}/> */}
                    </Routes>
                <FooterPublic />
        </>
            
        
    )
}
