import React, { useEffect, useState } from 'react'
import { Routes, Route } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { LoginScreen } from '../Component/login/LoginScreen';
import {   recuperarLoginActionAxios } from '../actions/auth'
import { BrowserRouter } from "react-router-dom";
import { PrivateRoute } from './PrivateRoute';
import { DashboardRoutes } from './DashboardRoutes';
import { PublicRoute } from './PublicRoute';
import { RegisterScreen } from '../Component/register/RegisterScreen';




export const AppRouter = () => {

    const dispatch = useDispatch();
   
    //Es un estat local, per esperar el servei de recuperar login. 
    const [fiRevisarSessio, setFiRevisarSessio] = useState(false);
    const [estaRecuperacioLogin, setEstatRecuperacioLogin] = useState(false);
    const [loginat, setLoginat]  = useState(false);
   
 
    const { auth } = useSelector(state => state);
    
    useEffect(() => {
       
        //recuperarLoginTest

        //setEstaLoginat(true);
       // setTimeout(function() {

        dispatch(recuperarLoginActionAxios());
        setFiRevisarSessio(true); //Marquem l'estat a true, per saber que a finalitzar de revisar si existeix sessio al servidor
        setEstatRecuperacioLogin(false);
        


        //}, 3000);

    },[]);
        //Mirarem si existeix sessió al servidor, si es així, la recuperem
    


     if(fiRevisarSessio === false)
     {
         //Aquesta part es mostra just quan s'està verificant si existeix una sessió iniciada.
         //Todo - Fer un component de càrrega més maco
        return(
            <h1>Estem revisant si existeix una sessió iniciada previament... </h1>
        )

     }else
     {
        if(auth.tokenJwt !== undefined && !estaRecuperacioLogin)
        {
           
            setEstatRecuperacioLogin(true);
            setLoginat(true);

            //console.log(auth.tokenJwt);
            //setLoginat(true);
            
        }
      
        

     }

    

    
  
    return (
        
        <BrowserRouter>
        <Routes>
             {/* <Route path="/login" element={<LoginScreen />} /> */}
             <Route path="/login/*" element={
                    <PublicRoute>
                        <LoginScreen estaLoginat={loginat} />
                    </PublicRoute>
                } 
                />
               

                <Route path="/*" element={ 
                        <PrivateRoute estaLoginat={loginat}>
                            <DashboardRoutes />
                        </PrivateRoute>
                    } 
                />

        </Routes>
        </BrowserRouter>
        
    )
}
