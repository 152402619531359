import { types } from "../types/types"
export const recuperarHistorial = (token) =>{
    //Aquesta funció (Middleware), retorna un callback. Just quan finalitza la petició, execitarà l'acció 
    return (dispatch) =>{
        //dispatch(uiIniciarCarregaAction());      
            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8','Accept':'application/json','Authorization' : token },
                credentials: 'include',
                mode: 'cors'
            };
            //TODO - Crear un fitxer on guardem constants segons l'entorn 
        fetch( process.env.REACT_APP_DOMINI_API_CIUDAD + '/historic',requestOptions)
            .then(async response => {
               
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();
                
        
                // check for error response
                if (!response.ok) {
                    
                    // get error message from body or default to response status
                    //const error = (data && data.message) || response.status;
                    //dispatch(crearErrorAction("Error del servei d'autenticació: " +  JSON.stringify(data)));
                }else
                {
                    if(data.ok)
                    {
                    
                       dispatch(configAplicacioRecuperarHistoric(data.contingut));
                    }else
                    {

                    }
                }

                //dispatch(uiFinalitzarCarregaAction());
                //element.innerHTML = data.id;
            })
            .catch(error => {
               // element.parentElement.innerHTML = `Error: ${error}`;
               // dispatch(crearErrorAction("Excepció no controlada -  " + JSON.stringify(error)));
                console.error('There was an error!', error);
               // dispatch(uiFinalitzarCarregaAction());
            });
      
        /*setTimeout(() => {
                //Als 3 segons, farem un dispatch del l'acció Login. 
                
                dispatch(login(123,345,"German, usuari autenticat"));

            }, 3500);*/
    }


}
export const recuperarHistorial2 = (token) =>{
    //Aquesta funció (Middleware), retorna un callback. Just quan finalitza la petició, execitarà l'acció 
    return (dispatch) =>{
        //dispatch(uiIniciarCarregaAction());      
            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8','Accept':'application/json','Authorization' : token },
                credentials: 'include',
                mode: 'cors'
            };
            //TODO - Crear un fitxer on guardem constants segons l'entorn 
        fetch( process.env.REACT_APP_DOMINI_API_CIUDAD + '/historic2',requestOptions)
            .then(async response => {
               
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();
                
        
                // check for error response
                if (!response.ok) {
                    
                    // get error message from body or default to response status
                    //const error = (data && data.message) || response.status;
                    //dispatch(crearErrorAction("Error del servei d'autenticació: " +  JSON.stringify(data)));
                }else
                {
                    if(data.ok)
                    {
                    
                       dispatch(configAplicacioRecuperarHistoric(data.contingut));
                    }else
                    {

                    }
                }

                //dispatch(uiFinalitzarCarregaAction());
                //element.innerHTML = data.id;
            })
            .catch(error => {
               // element.parentElement.innerHTML = `Error: ${error}`;
               // dispatch(crearErrorAction("Excepció no controlada -  " + JSON.stringify(error)));
                console.error('There was an error!', error);
               // dispatch(uiFinalitzarCarregaAction());
            });
      
        /*setTimeout(() => {
                //Als 3 segons, farem un dispatch del l'acció Login. 
                
                dispatch(login(123,345,"German, usuari autenticat"));

            }, 3500);*/
    }


}
export const configAplicacioRecuperarHistoric = (contingut) =>{
    return {
        type: types.configAplicacioRecuperarHistoric,
        contingut
    }

}