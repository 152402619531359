import React from 'react'
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addFormularioCat1, addFormularioCat7, addProgramaCat7 } from '../../actions/configFormularioAction';
import { crearErrorAction } from '../../actions/ui';
import { calculoPorcentaje } from '../../helpers/FormulasCat';
import { useForm } from "../../hooks/useForm";
import { useTranslation } from "react-i18next";

export const FormularioCat7 = () => {
  const dispatch = useDispatch(); 
  const [t, i18n] = useTranslation("generic");
  const state = useSelector((state) => state);
  const { msgError } = useSelector((state) => state.ui);
  
  const { auth,configFormulario} = useSelector(state => state);
  const [formValues, handleInputChange,reset] = useForm({
    cat7_1: "",
    cat7_2: "",
    cat7_3: "",
    cat7_4: "",
    cat7_5: "",  
});

const { cat7_1, cat7_2, cat7_3, cat7_4, cat7_5} = formValues;

  const handleCalcularCat = (e) => {
    
    if(validarFormulas()){
      
      dispatch(addFormularioCat7(auth.tokenJwt,cat7_1, cat7_2, cat7_3, cat7_4, cat7_5,configFormulario.infoFormulario.IdEvaluacion));
      reset();
      

    }else{
      console.log("out");
      //setMsgError("")
    }
  };
  const validarFormulas = () => {
    let esCorrecte = true;
    let msgError ="";
    if(isNaN(cat7_1) || !cat7_1 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 7.1 + t("formulario.msgError2");}
    if(isNaN(cat7_2) || !cat7_2 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 7.2 + t("formulario.msgError2");}
    if(isNaN(cat7_3) || !cat7_3 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 7.3 + t("formulario.msgError2");}
    if(isNaN(cat7_4) || !cat7_4 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 7.4 + t("formulario.msgError2");}
    if(isNaN(cat7_5) || !cat7_5 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 7.5 + t("formulario.msgError2");}
    if(!esCorrecte){
      dispatch(crearErrorAction(msgError));
    }
    return esCorrecte;
  }
  const handleCalcularPrograma = (e) => {
    if(validarProgramas()){ 
      dispatch(addProgramaCat7(auth.tokenJwt,cat7_1, cat7_2,configFormulario.infoFormulario.IdEvaluacion));
    }else{
      console.log("out");
    }
  };
  const validarProgramas = () => {
    let esCorrecte = true;
    let msgError ="";
    if(isNaN(cat7_1) || !cat7_1 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 7.1 + t("formulario.msgError2");}
    if(isNaN(cat7_2) || !cat7_2 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 7.2 + t("formulario.msgError2");} 
    if(!esCorrecte){
      dispatch(crearErrorAction(msgError));
    }
    return esCorrecte;
  }

  return (
    <>
    <div className='ac-panel'>
      <div className="ac-text !p-9 !pt-3 !text-[15px] !font-normal !leading-7 !text-dark-light">
        {msgError != null  ? ( //això es una condició, si es diferent de null
                      <div className="alert alert-danger">{msgError}</div>
                    ) : null}
                    {configFormulario.infoFormularioCategoria.ResultatCat7==null?
    <>
        <div className='sm:col-span-2'>
          <label htmlFor="cat7_1">{t("Categoria7.preg1")}</label>
          <select className='form-inputCat' id={cat7_1} name="cat7_1" defaultValue="" onChange={handleInputChange}>
            <option value=''>{t("CategoriaDesplegable.seleccionar")}</option>
            <option value="3">{t("CategoriaDesplegable.7.1_3")} </option>
            <option value="2">{t("CategoriaDesplegable.7.1_2")} </option>
            <option value="1">{t("CategoriaDesplegable.7.1_1")} </option>
            <option value="0">{t("CategoriaDesplegable.7.1_0")}</option>
          </select>
        </div>
        <div className='sm:col-span-2'>
          <label htmlFor="cat7_2">{t("Categoria7.preg2")} </label>
          <select className='form-inputCat' id={cat7_2} name="cat7_2" defaultValue="" onChange={handleInputChange}>
            <option value=''>{t("CategoriaDesplegable.seleccionar")}</option>
            <option value="3">{t("CategoriaDesplegable.7.2_3")} </option>
            <option value="2">{t("CategoriaDesplegable.7.2_2")} </option>
            <option value="1">{t("CategoriaDesplegable.7.2_1")} </option>
            <option value="0">{t("CategoriaDesplegable.7.2_0")}</option>
          </select>
        </div>
        <div className='sm:col-span-2'>
          <label htmlFor="cat7_3">{t("Categoria7.preg3")} </label>
          <select className='form-inputCat' id={cat7_3} name="cat7_3" defaultValue="" onChange={handleInputChange}>
            <option value=''>{t("CategoriaDesplegable.seleccionar")}</option>
            <option value="2">{t("CategoriaDesplegable.7.3_2")} </option>
            <option value="1">{t("CategoriaDesplegable.7.3_1")} </option>
            <option value="0">{t("CategoriaDesplegable.7.2_0")}</option>
          </select>
        </div>
        <div className='sm:col-span-2'>
          <label htmlFor="cat7_4">{t("Categoria7.preg4")} </label>
          <input className='form-inputCat' type="text" name="cat7_4" id="cat7_4"  onChange={handleInputChange} required/>
        </div>
        <div className='sm:col-span-2'>
          <label htmlFor="cat7_5">{t("Categoria7.preg5")} </label>
          <input className='form-inputCat' type="text" name="cat7_5" id="cat7_5"  onChange={handleInputChange} required/>
        </div>
      
        <div><button onClick={handleCalcularCat} id='cat1' className="btn-turquesa">{t("formulario.enviar")}</button></div>
        </>
      :<>
      <div className='sm:col-span-2'>
          <label htmlFor="cat7_1">{t("CategoriaPrograma.7_1")} </label>
          <select className='form-inputCat' id={cat7_1} name="cat7_1" value={cat7_1} onChange={handleInputChange}>
            <option value=''>{t("CategoriaDesplegable.seleccionar")}</option>
            <option value="2">{t("CategoriaDesplegable.si")} </option>
            <option value="1">{t("CategoriaDesplegable.mitat")} </option>
            <option value="0">{t("CategoriaDesplegable.no")}</option>
          </select>        </div>
        <div className='sm:col-span-2'>
          <label htmlFor="cat7_2">{t("CategoriaPrograma.7_2")} </label>
          <select className='form-inputCat' id={cat7_2} name="cat7_2" value={cat7_2} onChange={handleInputChange}>
            <option value=''>{t("CategoriaDesplegable.seleccionar")}</option>
            <option value="2">{t("CategoriaDesplegable.si")} </option>
            <option value="1">{t("CategoriaDesplegable.mitat")} </option>
            <option value="0">{t("CategoriaDesplegable.no")}</option>
          </select>        </div>
    <div><button onClick={handleCalcularPrograma} id='cat1' className="btn-turquesa">{t("formulario.enviar")}</button></div>
    </> 
    }
      </div>
    </div>
    </>
  )
}
