import { types } from "../types/types";

export const authReducer = (state = {},action) => {

    switch (action.type){
        case types.login:
            return{
                ...state,
                tokenJwt : action.contingut.tokenJwt,
                id : action.contingut.id,
                nombre : action.contingut.nombre,
                apellido: action.contingut.apellido, 
                admin: action.contingut.admin,
                IdMunicipio: action.contingut.IdMunicipio
            }
            break;
        case types.logout:
            return{
                
            }
            break;
        case types.recuperarLogin:
            return{
                ...state,
                tokenJwt : action.contingut.tokenJwt,
                id : action.contingut.id,
                nombre : action.contingut.nombre,
                apellido: action.contingut.apellido, 
                admin: action.contingut.admin,
                IdMunicipio: action.contingut.IdMunicipio
            }
        case types.renovarToken:
            return{
                ...state,
                tokenJwt : action.contingut.tokenJwt,
                id : action.contingut.id,
                nombre : action.contingut.nombre,
                apellido: action.contingut.apellido, 
                admin: action.contingut.admin,
                IdMunicipio: action.contingut.IdMunicipio
            }
        default:
            return state;
    }

}