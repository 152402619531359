import { types } from "../types/types";

export const configAplicacioReducer = (state = {},action) => {

    switch (action.type){
        case types.configAplicacioRecuperarHistoric:
            return {...state,
                    
            contingut : action.contingut
            }
        default:
            return state;
    }

}